import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import * as SC from './SynonymCard.styled';
import useCorrectionStore from '../../../../Hooks/useCorrectionStore';
import useSynonymList from '../../../../Hooks/useSynonymList';
import { correctSynonymAction, getSynonymsCleanAction, highlightSynonymCorrectionAction } from '../../../../Store/PadState';
import { ChevronUpIcon } from '../../../Icons';
import { Pill } from '../../../Pill';

type SynonymCardProps = {

};

const SynonymCard: React.FC<SynonymCardProps> = () => {
    const dispatch = useDispatch();
    const synonymQuery = useCorrectionStore(store => store.synonymQuery);
    const { data: synonymList } = useSynonymList();
    const [isExpanded, setIsExpanded] = useState(false);

    const hasQuery = !!synonymQuery;
    const isEmpty = hasQuery && !synonymList?.length;

    const handleSectionExpand = useCallback(() => {
        setIsExpanded(prevState => !prevState);
    }, []);

    const getSynonymClickHandler = ({ entityKey, originalWord, synonym }: { entityKey?: string, originalWord: string, synonym: string }) => () => {
        dispatch(correctSynonymAction({
            adviceHistory: {
                entityKey,
                correctedText: synonym,
                originalError: originalWord,
                length: synonym?.length ?? 0
            }
        }));
    };

    useEffect(() => {
        const handleCleanSynonyms = () => {
            dispatch(getSynonymsCleanAction());
        }

        if (hasQuery) {
            setIsExpanded(true);
            window.addEventListener('click', handleCleanSynonyms);
        }

        return () => {
            window.removeEventListener('click', handleCleanSynonyms);
        };
    }, [dispatch, hasQuery]);

    return (
        <SC.Wrapper>
            <SC.SynonymCardTitle isExpanded={isExpanded} onClick={handleSectionExpand}>
                <p>Synonyme</p>
                <ChevronUpIcon />
            </SC.SynonymCardTitle>
            {isExpanded && (
                <SC.SynonymsBody>
                    {!synonymList?.length ? <SC.NoSynonyms>
                        {isEmpty ? <h1><SC.Highlited>{synonymQuery}</SC.Highlited></h1> : <h1>Für ausdrucksstarke Texte</h1>}
                        {isEmpty ? <p>Für dieses Wort sind keine Synonyme verfügbar.</p> : <p>Mit Doppelklick oder <SC.Highlited>Markierung</SC.Highlited> eines Wortes erhalten Sie passende Vorschläge.</p>}
                    </SC.NoSynonyms> : synonymList.map((item, index) => (
                            <SC.SynonymItem key={index}>
                                {item.prag_info && <SC.PragInfo
                                >
                                    {item.prag_info}:
                                </SC.PragInfo>}
                                {item.synonyms.map((synonym, index) => (
                                    synonym && (
                                        <Pill
                                            key={index}
                                            fontSize={13}
                                            onClick={getSynonymClickHandler({
                                                synonym,
                                                entityKey: item.entityKey,
                                                originalWord: item.originalWord
                                            })}
                                            onMouseEnter={() => dispatch(highlightSynonymCorrectionAction({ entityKey: item.entityKey as string, replacement: synonym }))}
                                            onMouseLeave={() => dispatch(highlightSynonymCorrectionAction({}))}
                                        >
                                            {synonym}
                                        </Pill>
                                    )
                                ))}
                            </SC.SynonymItem>
                        )
                    )}
                </SC.SynonymsBody>
            )}
        </SC.Wrapper>
    );

};

export default SynonymCard;
