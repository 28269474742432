import React from 'react';

import { useQuery } from '@tanstack/react-query'

import * as SC from './CorrectionHandshake.styled';
// import useCorrectionStore from '../../Hooks/useCorrectionStore';
import { RequestApi, RequestMethod } from '../../Util/RequestApi';

type CorrectionHandshakeProps = {
    predefinedText?: string;
};

const CorrectionHandshake: React.FC<CorrectionHandshakeProps> = ({ predefinedText }) => {
    // const text = useCorrectionStore(state => state.text);
    const { data: welcomeText, isLoading } = useQuery({
            queryKey: ['welcomeText'],
            queryFn: async () => {
                try {
                    const response = await RequestApi.fetch(RequestMethod.get, 'api/welcome');

                    if (response.status !== 200) {
                        return '';
                    }

                    return await response.text();
                } catch (_err) {
                    return '';
                }
            },
            staleTime: Infinity,
            ...(!!predefinedText && { initialData: predefinedText }),
        }
    )

    // const textLength = text.length;

    if (isLoading || !welcomeText) {
        return null;
    }

    return (
        <SC.Wrapper dangerouslySetInnerHTML={{__html: welcomeText}}/>
    );
};

export default CorrectionHandshake;
