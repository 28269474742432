import React, { useState } from 'react';

import {useSelector} from 'react-redux';

import {selectTextLength} from '../../Store/PadState';
import {RequestApi, RequestMethod} from '../../Util/RequestApi';

/* tslint:disable-next-line:ordered-imports | welcome message from backend */
import '../../Styles/component/authoring/handshake/handshake.sass';

type HandshakeProps = {
    welcomeText?: string;
}

export const Handshake: React.FC<HandshakeProps> = ({ welcomeText: initWelcomeText = '' }) => {
    const [welcomeText, setWelcomeText] = useState('');
    const textLength = useSelector(selectTextLength);

    if (!initWelcomeText && textLength !== 0) {
        return null; // tslint:disable-line:no-null-keyword
    }

    if (!initWelcomeText && welcomeText === '') {
        RequestApi.fetch(RequestMethod.get, 'api/welcome').then(async (response: Response) => {
            if (response.status !== 200) {
                return;
            }

            setWelcomeText(await response.text());
        });
    }

    return (
        <div className="desk__right-append" dangerouslySetInnerHTML={{__html: initWelcomeText || welcomeText}}/>
    );
};
