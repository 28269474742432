import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { AppMenuItem } from "./AppMenuItem";
import ExpressAppMenu from "./ExpressAppMenu/ExpressAppMenu";
import useOneClickStore, { OneClickStore } from "../../Hooks/useOneClickStore";
import { selectAddedEntries, resetDictionaryCounterAction } from "../../Store/DictionaryState";
import { selectUserFeatures, selectUserRoles, UserRole } from "../../Store/UserState";
import "../../Styles/component/navigation/launcher/launcher.sass";
import { GTMDataLayerPush } from "../../Util/GoogleTagManager";
import { ToastOverlay, toastPointerDirection, toastType } from "../../Util/ToastOverlay";
import { hasFeature, hasRole } from "../../Util/UserUtils";

export const AppMenu: React.FC = () => {
    const [showDictionaryToast, setShowDictionaryToast] = useState(false);
    const [showSettingsToast, setShowSettingsToast] = useState(false);
    const addedEntries = useSelector(selectAddedEntries);
    const userFeatures = useSelector(selectUserFeatures);
    const userRoles = useSelector(selectUserRoles);
    const isHomeRoute = useRouteMatch({ exact: true, path: "/" });
    const isDictionaryRoute = useRouteMatch({ exact: true, path: "/dictionary" });
    const isSettingsRoute = useRouteMatch({ exact: true, path: "/settings" });
    const isExtensionsRoute = useRouteMatch({ exact: true, path: "/extensions" });
    const dispatch = useDispatch();
    const isOneClickModeActive = useOneClickStore((store: OneClickStore) => store.isOneClickModeActive);

    const openDictionaryToast = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        dispatch(resetDictionaryCounterAction());

        GTMDataLayerPush({event: "app-menu", option: "dictionary"})
        if (!hasFeature("accessDictionary", userFeatures)) {
            event.preventDefault();
            setShowDictionaryToast(true);
        }
    };
    const closeDictionaryToast = () => setShowDictionaryToast(false);

    const openSettingsToast = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        GTMDataLayerPush({event: "app-menu", option: "settings"})
        if (hasRole(UserRole.Gast, userRoles)) {
            event.preventDefault();
            setShowSettingsToast(true);
        }
    };
    const closeSettingsToast = () => setShowSettingsToast(false);

    return (
        <nav className="launcher">
            {isOneClickModeActive ? (
                <ExpressAppMenu />
            ) : (
                <>
                    <AppMenuItem
                        isActive={!!isHomeRoute}
                        iconPath="/assets/icons/appmenu/spellcheck.svg"
                        href="/"
                        text="Textkorrektur"
                        tooltip="Textprüfung"
                        type="spellcheck"
                        onClick={() => GTMDataLayerPush({event: "app-menu", option: "spellcheck"})}
                    />
                    <AppMenuItem
                        indicator={addedEntries}
                        isActive={!!isDictionaryRoute}
                        href="/dictionary"
                        text="Meine Wörter"
                        iconPath="/assets/icons/appmenu/dictionary.svg"
                        tooltip={!showDictionaryToast ? "Mein persönliches Wörterbuch" : undefined}
                        onClick={openDictionaryToast}
                        type="dictionary"
                    >
                        {showDictionaryToast && (
                            <ToastOverlay
                                onOverlayClose={closeDictionaryToast}
                                positionOffset={{ top: "145px", left: "75px" }}
                                pointerOffset={{ left: "-1px", top: "50%" }}
                                type={toastType.ink}
                                pointerDirection={toastPointerDirection.left}
                            >
                                Mein persönliches Wörterbuch - <a href="/user/register">Registrieren Sie sich</a>,<br /> um unbekannte Wörter, Eigennamen oder Fachbegriffe zu speichern.
                            </ToastOverlay>
                        )}
                    </AppMenuItem>
                    <AppMenuItem
                        isActive={!!isSettingsRoute}
                        href="/settings"
                        text="Einstellungen"
                        iconPath="/assets/icons/appmenu/settings.svg"
                        tooltip={!showSettingsToast ? "Einstellungen" : undefined}
                        type="dictionary"
                        onClick={openSettingsToast}
                    >
                        {showSettingsToast && (
                            <ToastOverlay
                                onOverlayClose={closeSettingsToast}
                                positionOffset={{ top: "220px", left: "75px" }}
                                pointerOffset={{ left: "-1px", top: "50%" }}
                                type={toastType.ink} pointerDirection={toastPointerDirection.left}
                            >
                                Einstellungen - <a href="/user/register">Registrieren Sie sich</a>,<br /> um Einstellungen zur Textprüfung vorzunehmen.
                            </ToastOverlay>
                        )}
                    </AppMenuItem>
                    <AppMenuItem
                        href="/extensions"
                        isActive={!!isExtensionsRoute}
                        iconPath="/assets/icons/appmenu/extensions.svg"
                        text="Erweiterungen"
                        tooltip="Erweiterungen"
                        type="extensions"
                        onClick={() => GTMDataLayerPush({event: "app-menu", option: "extensions"})}
                    />
                </>
            )}
        </nav>
    );
};
