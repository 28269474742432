import React from 'react';

import styled from 'styled-components';

import { useCorrectionAdvice } from '../../Hooks/useCorrectionAdvice';
import { useCorrectionInlineAdviceItem } from '../../Hooks/useCorrectionInlineAdviceItem';
import { ADVICE_TYPE, NormalizedAdvice } from '../../Hooks/useCorrectionStore';
import { Fonts } from '../../Styles';
import { Colors } from '../../Styles';
import { GTMDataLayerPush } from '../../Util/GoogleTagManager';
import { SettingsInformation } from '../Advice/SettingsInformation';
import { RoundButton } from '../Buttons';
import { AcceptAllIcon, AcceptIcon, DictionaryIcon } from '../Icons';
import { Pill } from '../Pill';

export type CorrectionInlineAdviceProps = {
    advice: NormalizedAdvice;
    markerPositionLeft: number;
    markerPositionTop: boolean;
    setNextAdviceItem?: () => void; // TODO: try to avoid
}

interface StyleProps {
    markerPositionLeft: number;
    markerPositionTop: boolean;
}

const StyledInlineAdvice = styled.div<StyleProps>`
    background: ${Colors.white};
    color: ${Colors.mineShaft};
    border-radius: 6px;
    padding: 7px 12px 5px;
    box-shadow: 0 2px 24px 0 rgba(52, 57, 60, 0.32);
    width: 255px;

    &:before {
        content: "";
        position: absolute;
        border-style: solid;
        border-color: ${Colors.white} transparent;
        display: block;
        width: 0;
        border-width: ${(p: StyleProps) => p.markerPositionTop ? '0 15px 10px' : '10px 15px 0'};
        bottom: ${(p: StyleProps) => p.markerPositionTop ? 'auto' : '-10px'};
        top: ${(p: StyleProps) => p.markerPositionTop ? '-5px' : 'auto'};
        left: ${(p: StyleProps) => p.markerPositionLeft ? `${p.markerPositionLeft}px` : '20px'};
        right: auto;
        z-index: 1;
    }

    .advice__proposal {
        font-size: 14px;
    }

    .advice__label {
        font-size: 15px;
        display: block;
        width: 100%;
        margin-bottom: 10px;
    }

    .short-message {
        font-size: 15px;
    }
`;

const StyledProposalWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 3px 4px;
`;

const StyledAdditionalShortMessage = styled.div`
    margin: 0 3px 10px;
`;

const StyledShortMessage = styled.span`
    font-family: ${Fonts.theinhardt};
    font-size: 15px;
`;

const StyledAdvicePanelButton = styled.button`
    border: none;
    background: ${Colors.white};
    color: ${Colors.mineShaft};
    cursor: pointer;
    padding: 4px 3px 3px;
    font-size: 13px;
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 0 7px;
    border-radius: 19px;

    &:hover {
        background: ${Colors.mystic};
    }

    svg {
        vertical-align: middle;
        margin-top: -1px;
        fill: currentColor;
        width: 25px;
        height: 25px;
        margin-right: 7px;
    }
`;

const StyledAdvicePanelIgnoreButton = styled(StyledAdvicePanelButton)`
    font-size: 13px;
    font-weight: 500;
    width: auto;
    border-radius: 4px;
    background: ${Colors.mystic};
    padding: 5px 10px 3px 10px;

    span {
        color: ${Colors.rollingStone};
    }

    &:hover {
        background: ${Colors.mystic};
    }
`;

const StyledAlternativeSuggestions = styled.div`
    font-family: "Theinhardt", monospace, sans-serif;
    padding: 0 2px;
    margin: 13px 0 10px;
    font-size: 13px;
`;

const StyledProposalHeading = styled.h3`
    text-transform: uppercase;
    font-family: ${Fonts.theinhardt};
    font-size: 10px;
    font-weight: bold;
    color: ${Colors.osloGray};
    line-height: 1.38;
    letter-spacing: 1px;
    margin: 0 0 7px;
`;

const StyledLabel = styled.span`
    font-family: ${Fonts.theinhardt};
    font-size: 18px;
`;
const StyledProposal = styled.span`
    font-family: ${Fonts.publico};
    font-weight: bold;
    font-size: 23px;
    overflow: hidden;
    word-wrap: break-word;
    &:hover {
        cursor: pointer;
    }
`;


const CorrectionInlineAdvice: React.FC<CorrectionInlineAdviceProps> = (props) => {
    const { closeInlineAdviceItem } = useCorrectionInlineAdviceItem();
    const adviceType = props.advice.type === 'style' ? 'STYLE-ERROR' : 'SPELL-ERROR';
    const {
        isAcceptable,
        isMultiple,
        showAdditionalShortMessage,
        hasSettingsInformation,
        accept,
        showSuggestion,
        hideSuggestion,
        canAddToDictionary,
        addToDictionary,
    } = useCorrectionAdvice(props.advice, props.advice.type === 'style' ? ADVICE_TYPE.Style : ADVICE_TYPE.Spelling);

    /**
     * accept all occurrences of an error with specific proposal/text
     */
    const onClickAccept = (alternative: string) => {
        GTMDataLayerPush({ event: 'text-advice', option: 'accepted', location: 'inline-advice', 'advice-type': adviceType === 'SPELL-ERROR' ? 'spelling' : 'style' });
        accept(alternative);
        closeInlineAdviceItem();
        // props.setNextAdviceItem();
    };

    const onClickAlternatives = (alternative: string) => {
        GTMDataLayerPush({ event: 'select-alternative', 'advice-type': adviceType === 'SPELL-ERROR' ? 'spelling' : 'style', location: 'inline-advice' });
        accept(alternative);
    };

    const onClickReject = () => {
        GTMDataLayerPush({ event: 'text-advice', option: 'rejected', location: 'inline-advice', 'advice-type': adviceType === 'SPELL-ERROR' ? 'spelling' : 'style' });
        // reject();
        closeInlineAdviceItem();
        // props.setNextAdviceItem();
    };

    const onClickDictionary = () => {
        GTMDataLayerPush({ event: 'save-in-dictionary', location: 'inline-advice', 'advice-type': adviceType === 'SPELL-ERROR' ? 'spelling' : 'style' });
        addToDictionary();
    };

    const getSuggestion = () => {
        if (!isAcceptable) {
            return (
                <StyledLabel className="advice__label">
                    {props.advice.shortMessage.trim()}
                    {hasSettingsInformation && <SettingsInformation />}
                </StyledLabel>
            );
        }

        if (props.advice.proposals.length === 0 && isAcceptable) {
            return (
                <StyledProposal
                    className="advice__proposal"
                    onClick={() => accept(" ")}
                    onMouseEnter={() => showSuggestion(' ')}
                    onMouseLeave={hideSuggestion}
                >
                    <del>{props.advice.originalError}</del>
                </StyledProposal>
            );
        }
        return <StyledProposal
            className="advice__proposal"
            onClick={() => accept(props.advice.proposals[0])}
            onMouseEnter={() => showSuggestion(props.advice.proposals[0] || ' ')}
            onMouseLeave={hideSuggestion}
        >
            {props.advice.proposals[0]}
        </StyledProposal>;
    };

    return (
        <StyledInlineAdvice markerPositionLeft={props.markerPositionLeft} markerPositionTop={props.markerPositionTop}>
            <StyledProposalWrapper>
                {getSuggestion()}
                {isAcceptable && (
                    <RoundButton
                        defaultBackground={Colors.supernova}
                        hoverBackground={Colors.sun}
                        onMouseEnter={() => showSuggestion(props.advice.proposals[0] || ' ')}
                        onMouseLeave={hideSuggestion}
                        onClick={() => onClickAccept(props.advice.proposals[0] || ' ')}
                        style={{ flexShrink: 0 }}
                    >
                        {isMultiple ? <AcceptAllIcon /> : <AcceptIcon />}
                    </RoundButton>
                )}
            </StyledProposalWrapper>
            {showAdditionalShortMessage && (
                <StyledAdditionalShortMessage>
                    <StyledShortMessage>{props.advice.shortMessage}</StyledShortMessage>
                    {hasSettingsInformation && <SettingsInformation />}
                </StyledAdditionalShortMessage>
            )}

            {props.advice.proposals.length > 1 && (
                <StyledAlternativeSuggestions>
                    <StyledProposalHeading>Alternativen</StyledProposalHeading>
                    {props.advice.proposals.slice(1)
                        .slice(0, 3)
                        .map((alternative, index) => (
                            <Pill
                                key={index}
                                fontSize={13}
                                onClick={() => onClickAlternatives(alternative)}
                                onMouseEnter={() => showSuggestion(alternative)}
                                onMouseLeave={hideSuggestion}
                            >{alternative}</Pill>
                        ))}
                </StyledAlternativeSuggestions>
            )}
            <StyledAdvicePanelIgnoreButton
                onClick={() => {
                    onClickReject();
                }}
            >
                Ignorieren&nbsp;<span>(Strg/Ctrl + i)</span>
            </StyledAdvicePanelIgnoreButton>
            {canAddToDictionary && (
                <StyledAdvicePanelButton
                    onClick={() => {
                        onClickDictionary();
                    }}
                >
                    <DictionaryIcon /> In meinem Wörterbuch speichern
                </StyledAdvicePanelButton>
            )}
        </StyledInlineAdvice>
    );
};

export default CorrectionInlineAdvice;