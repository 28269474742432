import React, {useState} from 'react';

import classNames from 'classnames';

import * as SC from './Header.styled';
import {AccountIndicator} from '../AccountIndicator/AccountIndicator';
import '../../Styles/layout/header.sass';
import {Logo} from "../Logo/Logo";

type HeaderProps = {
    children?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = (props) => {
    const [menuVisible, setMenuVisible] = useState(false)

    return (
        <SC.Wrapper>
            <div className="logo"><Logo/></div>
                <SC.Button onClick={() => setMenuVisible(true)}>︙</SC.Button>
            <div className={
                classNames('main-navigation-mobile', {
                    'main-navigation-mobile--visible': menuVisible
                })
            }>
                <header className="main-navigation-mobile__header">
                    <button className="main-navigation-toggle main-navigation-toggle--close" onClick={() => setMenuVisible(false)}>╳</button>
                </header>
                {props.children}
                <AccountIndicator/>
            </div>
        </SC.Wrapper>
    );
};

export default Header;