export enum RequestMethod {
    post = 'POST',
    get = 'GET',
    delete = 'DELETE',
}

export class RequestApi {
    public static readonly URL: string = process.env.REACT_APP_API_URI!;
    public static jwt: string | undefined;

    public static fetch(method: RequestMethod, path: string, header?: Headers, body?: BodyInit): Promise<Response> {
        /**
         * enable cookies for all versions to enable profiling in lite version
         */
        let credentials = 'include';

        if (process.env.NODE_ENV !== "production") {
            credentials = 'include';
        }

        return fetch(`${RequestApi.URL}/${path}?_format=json`, {
            method,
            headers: RequestApi.getHeader(header),
            credentials: credentials as RequestCredentials,
            body,
        });
    }

    private static getHeader(customHeader?: Headers): HeadersInit {
        const header = customHeader || new Headers({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
        });

        if (RequestApi.jwt !== undefined) {
            header.append('Application-Authorization', `Bearer ${RequestApi.jwt}`);
        }

        if (process.env.REACT_APP_API_TEST_SERVER_TOKEN) {
            header.append('Authorization', process.env.REACT_APP_API_TEST_SERVER_TOKEN);
        }

        return header;
    }
}
