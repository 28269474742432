import React from "react";

import * as SC from "./ExpressHandshake.styled";
import useOneClickStore, { OneClickStore, PARAPHRASING_MODE, selectBufferText, selectIsSegmentsLoading, selectSegments } from "../../Hooks/useOneClickStore";
import { ExpressSinglePad } from "../ExpressSinglePad";

type ExpressHandshakeProps = {
    isPreview?: boolean;
}

export const ExpressHandshake: React.FC<ExpressHandshakeProps> = ({ isPreview }) => {
    const bufferText = useOneClickStore(selectBufferText);
    const segments = useOneClickStore(selectSegments);
    const isSingleCorrectionMode = useOneClickStore((store: OneClickStore) => store.singleCorrectionActiveIndex >= 0);
    const isParaphrasingModeActive = useOneClickStore((store: OneClickStore) => store.isParaphrasingModeActive);
    const paraphrasingMode = useOneClickStore((store: OneClickStore) => store.paraphrasingMode);
    const isCorrectionFinished = useOneClickStore((store: OneClickStore) => store.isCorrectionFinished);
    const isSegmentsLoading = useOneClickStore(selectIsSegmentsLoading);

    const correctionFinishedMessage = isParaphrasingModeActive ? 'Umformulierung abgeschlossen.' : 'Korrektur abgeschlossen.';

    if (isSegmentsLoading) {
        return null;
    }

    if (isSingleCorrectionMode) {
        return <>
            <SC.StatusTitle isCardView>{isCorrectionFinished && correctionFinishedMessage}</SC.StatusTitle>
            <ExpressSinglePad />
        </>
    }

    if (!isPreview && bufferText.length && segments.length) {
        return (
            <SC.Wrapper>
                {isCorrectionFinished && <SC.StatusTitle>{correctionFinishedMessage}</SC.StatusTitle>}
                <SC.Title>Klicken Sie auf einen Satz.</SC.Title>
                <SC.Description>
                    Dadurch werden alle Satzänderungen <br />
                    kenntlich gemacht.
                </SC.Description>
            </SC.Wrapper>
        );
    }

    if (isParaphrasingModeActive) {
        let tonalityInfo = '';

        switch (paraphrasingMode) {
            case PARAPHRASING_MODE.Neutral:
                tonalityInfo = 'Die Option „neutral“ ist Fehlerkorrektur und Inspiration zugleich: Es kommt zu leichten Umformulierungen, die Tonalität des Textes bleibt aber erhalten.';
                break;
            case PARAPHRASING_MODE.Lighter:
                tonalityInfo = 'Bei der Option „einfacher“ steht die Verständlichkeit im Vordergrund. Satzbau und Wortwahl werden vereinfacht.';
                break;
            case PARAPHRASING_MODE.Formal:
                tonalityInfo = 'Mit der Option „formeller“ wird Ihr Text gehobener formuliert und auf professionelle Kontexte angepasst.';
                break;
        }

        return <SC.Wrapper>
            <SC.Info>{tonalityInfo}</SC.Info>
            <SC.Image src={`/assets/icons/paraphrasingInfo/tonality_${paraphrasingMode}.png`} alt="Tonality" />
        </SC.Wrapper>;
    }

    return (
        <SC.Wrapper>
            <SC.InitTitle >Korrektur auf <br /> Knopfdruck</SC.InitTitle>
            <SC.InitDescription>
                Lassen Sie Ihre Texte mit nur einem Klick korrigieren.
            </SC.InitDescription>
        </SC.Wrapper>
    );
};

export default ExpressHandshake;
