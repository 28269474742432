import { Optimize } from "library/src/Components/Optimize/Optimize";
import { YieldLoveScript } from "library/src/Components/Ads/YieldLoveScript";
import { AlertSpace } from "library/src/Components/AlertSpace/AlertSpace";
import { Header, MobileNavigation } from "library/src/Components/Header";
import { DeskWrapper } from "library/src/Components/Desk";
import { Settings } from "library/src/Views/Settings";
import { Dictionary } from "library/src/Views/Dictionary";
import { SpellcheckFull } from "library/src/Views/SpellcheckFull/SpellcheckFull";
import { Extensions } from "library/src/Views/Extensions";
import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

export const Router: React.FC = () => {
    return (
        <>
            <BrowserRouter>
                <Header>
                    <AlertSpace />
                    <MobileNavigation/>
                </Header>
                <DeskWrapper>
                    <Switch>
                        <Route path="/" exact>
                            <SpellcheckFull />
                        </Route>
                        <Route path="/dictionary" component={Dictionary} />
                        <Route path="/settings" component={Settings} />
                        <Route path="/extensions" component={Extensions} />
                    </Switch>
                </DeskWrapper>
            </BrowserRouter>
            <YieldLoveScript />
            <Optimize />
        </>
    );
};
