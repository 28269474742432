import { useQuery } from '@tanstack/react-query';

import useCorrectionStore from './useCorrectionStore';
import { DocumentIdentifier } from '../Util/DocumentIdentifier';
import { RequestApi, RequestMethod } from '../Util/RequestApi';

const useSynonymList = () => {
    const synonymQuery = useCorrectionStore(store => store.synonymQuery);

    const response = useQuery<unknown[]>({
        queryKey: ['synonyms', synonymQuery],
        queryFn: async () => {
            try {
                const response = await RequestApi.fetch(
                    RequestMethod.post,
                    "api/synonyms",
                    undefined,
                    JSON.stringify({
                        word: synonymQuery,
                        numberOfReturnedSynonyms: 50,
                        documentID: DocumentIdentifier.get(),
                    })
                );

                if (response.status !== 200) {
                    return;
                }

                return await response.json();
            } catch (_err) {
                return;
            }
        },
        enabled: !!synonymQuery,
        staleTime: Number.POSITIVE_INFINITY,
    })

    return response;
}

export default useSynonymList;