import * as React from "react";

import { useSelector } from "react-redux";
import styled from "styled-components";

import { selectUserFeatures, selectUserStateFromMainState, selectUserTrialDaysRemaining } from "../../Store/UserState";
import {Fonts} from "../../Styles";
import { hasFeature, isPremiumOrTrialUser } from "../../Util/UserUtils";

const StyledStatusLabel = styled.span`
    border: 1px solid currentColor;
    border-radius: 3px;
    font-family: ${Fonts.theinhardt};
    font-size: 13px;
    line-height: 1.46;
    padding: 2px 6px 1px 8px;
    margin-right: 12px;
    white-space: nowrap;
`;

export const UserStatusLabel: React.FC = () => {
    const user = useSelector(selectUserStateFromMainState);
    const userFeatures = useSelector(selectUserFeatures);
    const trialDaysRemaining = useSelector(selectUserTrialDaysRemaining);

    if (!isPremiumOrTrialUser(user.roles) || hasFeature("displayAsTrial", userFeatures) && trialDaysRemaining <= 2 && trialDaysRemaining > 0) {
        return null;
    }
    return (
        <StyledStatusLabel className="user-status">
            {hasFeature("displayAsTrial", userFeatures) ? "Premium-Test" : "Premium"}
        </StyledStatusLabel>
    );
}