import React from "react";


import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import * as SC from "./MobileNavigation.styled";
import useOneClickStore, { OneClickStore } from "../../../Hooks/useOneClickStore";
import { selectAddedEntries } from "../../../Store/DictionaryState";
import { GTMDataLayerPush } from "../../../Util/GoogleTagManager";
import { AppMenuItem } from "../../AppMenu/AppMenuItem";
import ExpressAppMenu from "../../AppMenu/ExpressAppMenu/ExpressAppMenu";

const MobileNavigation: React.FC = () => {
    const isOneClickModeActive = useOneClickStore((store: OneClickStore) => store.isOneClickModeActive);
    const isHomeRoute = useRouteMatch({ exact: true, path: "/" });
    const addedEntries = useSelector(selectAddedEntries);
    const isDictionaryRoute = useRouteMatch({ exact: true, path: "/dictionary" });
    const isSettingsRoute = useRouteMatch({ exact: true, path: "/settings" });

    return (
        <SC.Wrapper>
            {isOneClickModeActive ? (
                <ExpressAppMenu isHeaderView />
            ) : <>
                <AppMenuItem
                    isActive={!!isHomeRoute}
                    iconPath="/assets/icons/appmenu/spellcheck.svg"
                    href="/"
                    type="spellcheck"
                    onClick={() => GTMDataLayerPush({ event: "app-menu", option: "spellcheck" })}
                />
                <AppMenuItem
                    indicator={addedEntries}
                    isActive={!!isDictionaryRoute}
                    href="/dictionary"
                    iconPath="/assets/icons/appmenu/dictionary.svg"
                    type="dictionary"
                />
                <AppMenuItem
                    isActive={!!isSettingsRoute}
                    href="/settings"
                    iconPath="/assets/icons/appmenu/settings.svg"
                    type="dictionary"
                />
            </>
            }
        </SC.Wrapper>
    );
};

export default MobileNavigation;