export type UserFeatureName = 'accessDictionary' | 'canActivatePrivateMode' | 'canActivateTrial' | 'check20kCharacters'
    | 'check40kCharacters' | 'displayAsPremium' | 'displayAsTrial' | 'fillerWords' | 'hideAlertSpace' | 'inlineAdvices'
    | 'noAdvertisements' | 'overlongSentences' | 'synonyms' | 'unlimitedDictionaryEntries' | 'wordFrequency' | 'debugErrorCodes';

export interface UserFeature {
    name: UserFeatureName;
    available: boolean;
}

export enum UserRole {
    Gast = 'anonymous',
    Authenticated = 'authenticated',
    Basis = 'duden_basic',
    B2B_Licensemanager_Basis = 'duden_licence_manager_basic',
    B2B_Licensee_Basis = 'duden_licensee_basic',
    Trial = 'duden_trial',
    Plus = 'duden_plus',
    Premium20 = 'duden_premium_monthly_995',
    Premium = 'duden_premium',
    B2B_Licensemanager_Premium20 = 'duden_license_manager_premium',
    B2B_Licensee_Premium20 = 'duden_licensee_premium',
    B2B_Licensemanager_charity_Premium = 'duden_license_manager_charity_premium',
    B2B_Licensee_charity_Premium = 'duden_licensee_charity_premium',
    PremiumTrial = 'duden_premium_trial',
}

export interface UserDataPayload {
    features: UserFeature[];
    id: number;
    username: string;
    roles: UserRole[];
    hasUsedTrial: boolean;
    trialDuration: number;
    trialDaysRemaining: number;
    characterLimit: number;
    autoLogInterval: number;
    showDiscount: boolean;
    hasHadPremium: boolean;
    checkSettings: CheckSettings;
    email: string;
    inlineAdvices: Record<string, string>;
    hide_consent: string;
    hide_onboarding: string;
}

export type languageSettings = "de" | "ch" | "at";

export interface CheckSettings {
    spelling: boolean;
    styleFiller: boolean;
    styleOverlong: boolean;
    styleOverlongWordCount: number;
    styleWordFrequency: boolean;
    applyDpfCommaRules: boolean;
    language: languageSettings;
    privateMode: boolean;
    liloVersion: string;
    llmVersion: string;
    messages?: { type: "error" | "success", text: string | JSX.Element }
}

export interface State extends UserDataPayload {
    dictionaryWordCount: number;
    hideStyleAdvice: boolean;
    jwt?: string;
}
