import styled from "styled-components";

import {Colors, Fonts} from "../../../../Styles";

type SynonymCardTitleProps = {
    isExpanded: boolean
}

export const Wrapper = styled.section`
    padding: 0 20px;
    background: ${Colors.white};
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
`;

export const SynonymCardTitle = styled.div<SynonymCardTitleProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: ${Fonts.theinhardt};
    color: ${Colors.mineShaft};
    font-size: 17px;
    font-weight: 700;
    line-height: 18px;

    p {
        cursor: pointer;
    }

    svg {
        transition-duration: 0.25s;
        cursor: pointer;
        transform: ${(p) => p.isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'};
    }
`;

export const SynonymsBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
`;

export const NoSynonyms = styled.div`
    h1 {
        color: ${Colors.mineShaft};
        font-size: 23px;
        font-family: ${Fonts.publico};
        font-weight: 400;
        line-height: 30px;
        word-wrap: break-word
    }

    p {
        color: ${Colors.mineShaft};
        font-size: 18px;
        font-family: ${Fonts.theinhardt};
        font-weight: 400;
        line-height: 22px;
        word-wrap: break-word;
    }

    mark {
        background: ${Colors.pastelGreen};
    }
`;

export const Highlited = styled.span`
    background: ${Colors.pastelGreen};
`;

export const SynonymItem = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 15px 10px 0;
    background: ${Colors.mystic} 75%;
    border-radius: 4px;
`;

export const PragInfo = styled.div`
    margin-right: 8px;
    color: ${Colors.mineShaft};
    font-size: 15px;
    font-family: ${Fonts.theinhardt};
    font-weight: 700;
    line-height: 22px;
    word-wrap: break-word;
`;