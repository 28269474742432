import * as React from "react";
import { FormEvent, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import * as SC from "./CheckSettingsForm.styled";
import { CheckSettings, removeMessagesAction, selectCheckSettings, selectUserRoles, UserRole } from "../../Store/UserState";
import { Colors } from "../../Styles";
import { FontTheinhardt } from "../../Styles/Font/Theinhardt/Theinhardt";
import { hasAnyRole } from "../../Util/UserUtils";
import { ArrowRightIcon } from "../Icons";
import { RoundedCheckIcon } from "../Icons/CheckIconRounded";
import { WarningIcon } from "../Icons/WarningIcon";
import { Banner } from "../StatusMessages/Banner";
import { Switch } from "../Switch/Switch";

import "../../Styles/component/settings/check-settings-panel.sass";


export type CheckSettingsFormProps = {
    mode?: "compact" | "full";
    onSubmit: (s: Partial<CheckSettings>) => void;
}

export const CheckSettingsForm: React.FC<CheckSettingsFormProps> = (props) => {
    const checkSettings = useSelector(selectCheckSettings);
    const roles = useSelector(selectUserRoles);
    const [wordCountError, setWordCountError] = useState<string | undefined>(undefined);
    const [styleCheck, setStyleCheck] = useState(
        checkSettings.styleFiller || checkSettings.styleWordFrequency || checkSettings.styleOverlong
    );
    const [tempSettings, setTempSettings] = useState(checkSettings);
    const dispatch = useDispatch();
    // const user = useSelector(selectUserStateFromMainState);
    const minimumWordCount = 5;
    const [banner, setBanner] = useState<{ type: "error" | "success"; text: string; icon: JSX.Element } | undefined>(
        undefined
    );
    const liloVersion = tempSettings.liloVersion;
    const llmVersion = tempSettings.llmVersion;
    const isSettingsForbidden = !hasAnyRole(
        roles,
        UserRole.Premium,
        UserRole.Premium20,
        UserRole.B2B_Licensemanager_Premium20,
        UserRole.B2B_Licensemanager_charity_Premium,
        UserRole.B2B_Licensee_Premium20,
        UserRole.B2B_Licensee_charity_Premium,
        UserRole.PremiumTrial,
    );

    /**
     * remove checkSettings messages after 4 seconds
     */
    useEffect(() => {
        if (banner) {
            setTimeout(() => {
                dispatch(removeMessagesAction());
                setBanner(undefined);
            }, 4000);
        }
    }, [banner, dispatch]);

    /**
     * update switches when checkSettings of user are loaded or changed
     */
    useEffect(() => {
        setTempSettings(checkSettings);
        setStyleCheck(checkSettings.styleFiller || checkSettings.styleWordFrequency || checkSettings.styleOverlong);
    }, [checkSettings]);

    /**
     * deactivate all style options, when main switch is deactivated
     * activate all style options, only when none was active
     */
    useEffect(() => {
        if (
            (!tempSettings.styleWordFrequency && !tempSettings.styleOverlong && !tempSettings.styleFiller) ||
            !styleCheck
        ) {
            onChangeSettings({styleWordFrequency: styleCheck, styleFiller: styleCheck, styleOverlong: styleCheck});
        }
    }, [styleCheck]);

    /**
     * activate main switch, when one style option is activated
     */
    useEffect(() => {
        if (tempSettings.styleFiller || tempSettings.styleOverlong || tempSettings.styleWordFrequency) {
            setStyleCheck(true);
        } else if (!tempSettings.styleFiller && !tempSettings.styleOverlong && !tempSettings.styleWordFrequency) {
            setStyleCheck(false);
        }
    }, [tempSettings.styleFiller, tempSettings.styleOverlong, tempSettings.styleWordFrequency]);

    const onChangeSettings = (newSettings: Partial<CheckSettings>) => {
        setTempSettings({...tempSettings, ...newSettings});
    };

    const onChangeWordCount = (event: FormEvent<HTMLInputElement>) => {
        const wordCount = Number(event.currentTarget.value)
        if (wordCount < minimumWordCount) {
            setWordCountError(`Bitte geben Sie mindestens ${minimumWordCount} ein.`);
        } else if (!Number.isInteger(wordCount)) {
            setWordCountError("Bitte geben Sie nur Ganzzahlen ein.");
        } else {
            setWordCountError(undefined);
        }
        onChangeSettings({styleOverlongWordCount: wordCount});
    };

    const onSubmitSettings = (event: React.MouseEvent | React.FormEvent) => {
        event.preventDefault();
        try {
            props.onSubmit(tempSettings);
            setBanner({
                type: "success",
                text: "Die Änderungen wurden gespeichert.",
                icon: <RoundedCheckIcon/>,
            });
        } catch (e) {
            setBanner({type: "error", text: "Hoppla! Etwas ist schiefgelaufen.", icon: <WarningIcon/>});
        }
    };

    return (
        <SC.SettingsView>
            {banner && (
                <Banner type={banner.type}>
                    <FontTheinhardt fontSize={15} fontWeight={700}>
                        {banner.text}
                    </FontTheinhardt>
                    {banner.icon}
                </Banner>
            )}
            <SC.Wrapper className="container">
                <h1>Einstellungen</h1>
                <form onSubmit={onSubmitSettings}>
                    <SC.FormGroup>
                        <p>Ländereinstellungen</p>
                        <span>Wenn Sie die jeweilige Ländereinstellung aktivieren, wendet der Mentor die passenden Rechtschreibregeln an und erkennt grammatische Eigenheiten. </span>
                        <SC.FormRow>
                            <label htmlFor="languageDE">Deutschland</label>
                            <Switch
                                checked={tempSettings.language === "de"}
                                onChange={() => onChangeSettings({language: "de"})}
                                type="radio"
                                id="languageDE"
                                name="language"
                            />
                        </SC.FormRow>
                        <SC.Divider/>
                        <SC.FormRow>
                            <label htmlFor="languageAT">Österreich</label>
                            <Switch
                                checked={tempSettings.language === "at"}
                                onChange={() => onChangeSettings({language: "at"})}
                                type="radio"
                                id="languageAT"
                                name="language"
                            />
                        </SC.FormRow>
                        <SC.Divider/>
                        <SC.FormRow>
                            <label htmlFor="languageCH">Schweiz</label>
                            <Switch
                                checked={tempSettings.language === "ch"}
                                onChange={() => onChangeSettings({language: "ch"})}
                                type="radio"
                                id="languageCH"
                                name="language"
                            />
                        </SC.FormRow>
                        <SC.Divider/>
                    </SC.FormGroup>
                    <SC.FormGroup style={{position: "relative"}}>
                        <p>Was soll geprüft werden?</p>
                        <span>Wenn Sie die jeweilige Prüfeinstellung aktivieren, schlägt Ihnen der Mentor passende Optimierungen vor.</span>
                        <SC.FormSection isForbidden={isSettingsForbidden}>
                            {isSettingsForbidden && <SC.ForbiddenWrapper>
                                <SC.ForbiddenContent>
                                    <p>Diese Optionen erhalten Sie mit einem Premium-Abonnement.</p>
                                    <SC.ForbiddenCta
                                        target="_blank"
                                        href={`${process.env.REACT_APP_DUDENDE_URL}/user/authenticate?destination=/abonnement%3Fitm_source=duden_mentor%26itm_medium=premium_int%26itm_campaign=benutzerkonto_einstellungen%26itm_content=button_zg-basis%26source=mentor`}
                                    >
                                        Premium abonnieren <ArrowRightIcon />
                                    </SC.ForbiddenCta>
                                </SC.ForbiddenContent>
                            </SC.ForbiddenWrapper>}
                            {/*<SC.FormRow>*/}
                            {/*    <label htmlFor="punctuation">Kommasetzung</label>*/}
                            {/*    <Switch*/}
                            {/*        type="checkbox"*/}
                            {/*        checked={tempSettings.applyDpfCommaRules}*/}
                            {/*        onChange={() =>*/}
                            {/*            onChangeSettings({applyDpfCommaRules: !tempSettings.applyDpfCommaRules})*/}
                            {/*        }*/}
                            {/*        disabled={isSettingsForbidden}*/}
                            {/*        id="punctuation"*/}
                            {/*    />*/}
                            {/*</SC.FormRow>*/}
                            <SC.Divider/>
                            <SC.FormRow>
                                <label htmlFor="style">Stil und Vokabular</label>
                                <Switch
                                    type="checkbox"
                                    checked={styleCheck}
                                    onChange={() => setStyleCheck(value => !value)}
                                    disabled={isSettingsForbidden}
                                    id="style"
                                />
                            </SC.FormRow>
                            <SC.Divider/>
                            <SC.FormRow>
                                <label htmlFor="wordFrequency">Wortdopplungen</label>
                                <Switch
                                    type="checkbox"
                                    checked={tempSettings.styleWordFrequency}
                                    disabled={isSettingsForbidden}
                                    onChange={() =>
                                        onChangeSettings({styleWordFrequency: !tempSettings.styleWordFrequency})
                                    }
                                    id="wordFrequency"
                                />
                            </SC.FormRow>
                            <SC.Divider/>
                            <SC.FormRow>
                                <label htmlFor="filler">Füllwörter</label>
                                <Switch
                                    type="checkbox"
                                    checked={tempSettings.styleFiller}
                                    disabled={isSettingsForbidden}
                                    onChange={() => onChangeSettings({styleFiller: !tempSettings.styleFiller})}
                                    id="filler"
                                />
                            </SC.FormRow>
                            <SC.Divider/>
                            <SC.FormRow>
                                <label htmlFor="overlong-sentence">Langer Satz</label>
                                <Switch
                                    type="checkbox"
                                    checked={tempSettings.styleOverlong}
                                    disabled={isSettingsForbidden}
                                    onChange={() =>
                                        onChangeSettings({styleOverlong: !tempSettings.styleOverlong})
                                    }
                                    id="overlong-sentence"
                                />
                            </SC.FormRow>
                            <SC.FormRow
                                className={"with_icon"}
                                style={{
                                    marginLeft: 25,
                                    display: !tempSettings.styleOverlong ? "none" : "",
                                }}
                            >
                                <label
                                    htmlFor="wordCount"
                                    style={{color: tempSettings.styleOverlong ? "unset" : Colors.mercury}}
                                >
                                    Wortanzahl pro Satz:
                                </label>
                                <div>
                                    <SC.NumberInput
                                        onChange={onChangeWordCount}
                                        disabled={!tempSettings.styleOverlong || isSettingsForbidden}
                                        value={`${tempSettings.styleOverlongWordCount}`}
                                        id="wordCount"
                                        type="number"
                                        min={minimumWordCount}
                                    />
                                </div>
                            </SC.FormRow>
                            {wordCountError && (
                                <SC.FormRow style={{marginLeft: 25}} className="error">
                                    {wordCountError}
                                </SC.FormRow>
                            )}
                        </SC.FormSection>
                        <SC.SubmitButton disabled={tempSettings.styleOverlongWordCount < minimumWordCount} type={"button"} onClick={onSubmitSettings}>Speichern</SC.SubmitButton>
                    </SC.FormGroup>
                    <SC.Version>{liloVersion}</SC.Version>
                    <SC.Version>{llmVersion}</SC.Version>
                </form>
            </SC.Wrapper>
        </SC.SettingsView>
    );
};

CheckSettingsForm.defaultProps = {mode: "full"};
