import { create } from 'zustand';
import { persist, createJSONStorage, subscribeWithSelector } from 'zustand/middleware';

import useOneClickStore from './useOneClickStore';
import { DocumentIdentifier } from '../Util/DocumentIdentifier';
import { getCaretPosition, normalizeGrammarcheckBlocks, setCaretPosition } from '../Util/oneClickCorrections';
import { RequestApi, RequestMethod } from '../Util/RequestApi';

type ObjectValues<T> = T[keyof T];

export const ADVICE_TYPE = {
    Spelling: 'spelling',
    Style: 'style',
} as const;
export type AdviceType = ObjectValues<typeof ADVICE_TYPE>;

export type GrammarcheckBlock = { id?: string; type: AdviceType | 'common'; copy: string };

const checkCorruptedChildNode = (node: HTMLDivElement | null) => {
    if (!node) return false;

    for (const childNode of node.childNodes) {
        if (!(childNode as HTMLSpanElement)?.classList?.contains('text-segment')) return true;
    }

    return false;
};

export type Segment = {
    id: string;
    beginOffset: number;
    // endOffset: number;
    currentText?: string;
    text: string;
    originText?: string;
    blocks?: GrammarcheckBlock[];
    spellAdvices?: NormalizedAdvice[];
    styleAdvices?: NormalizedAdvice[];
    // traceBlocks?: CorrectionBlock[];
    isStale?: boolean;
    // error?: boolean;
    // corrections?: LLMError[];
    issue?: {
        code?: string;
        title?: string;
        message?: string;
    }
};

export type Advice = {
    errorCode: string;
    errorMessage: string;
    shortMessage: string;
    length: number;
    offset: number;
    originalError: string;
    proposals: string[];
    synonyms: string[];
    occurrences: Array<{
        offset: number;
        text: string;
        synonyms: string[];
    }>;
    occurrenceIndex: number;
    type: string; // gram
    additionalInformation: string;
    entityKey: string; // TODO
}

export type NormalizedAdvice = Advice & { id: string, adviceType: AdviceType, segmentId: string }

export type CorrectionStore = {
    isAlternativeModeActive: boolean,
    setIsAlternativeModeActive: (value: boolean) => void;
    text: string;
    segments: Segment[];
    correctionMode: AdviceType;
    handleSetCorrectionMode: (mode: AdviceType) => void;
    bufferText: string;
    editorNode: HTMLDivElement | null;
    setEditorNode: (node: HTMLDivElement | null) => void;
    handleSegmentize: () => Promise<unknown>;
    isSegmentizeLoading: boolean;
    isSegmentizeSilentLoading: boolean;
    segmentsLoading: Record<string, boolean>;
    cleanEditorNode: () => void;
    synonymQuery?: string;
    handleSetSynonymQuery: (value: string) => void;
    acceptedAdvice: { id: string, segmentId: string, value?: string }  | null,
    handleAcceptAdvice: (data: CorrectionStore['acceptedAdvice']) => void,
    adviceSuggestion: { id: string, segmentId: string, suggestion: string } | null,
    handleSetAdviceSuggestion: (data: CorrectionStore['adviceSuggestion']) => void,
    charsRemaining?: number;
    handleGrammarcheck: () => void;
    handleSegmentUpdate: (data: { id: string; text?: string }) => void;
    handleSegmentGrammarcheck: (id: string) => Promise<boolean>;
    isGrammarcheckLoading: boolean;
    setBufferText: (text: string) => void;
    syncBufferText: () => void;
    activeIndex: number;
    selectedIndex: number;
    highlightedAdvice?: {
        id: string;
        entityId?: string;
    };
    handleSetHighlightedAdvice: (data: CorrectionStore['highlightedAdvice']) => void;
    handleSetActiveIndex: (props: { id?: string; value?: number; shiftValue?: number }) => void;
    handleStartToCloseInlineAdvice: () => void;
    handleStopToCloseInlineAdvice: () => void;
    closeInlineAdviceTimestamp?: NodeJS.Timer;
}

const useCorrectionStore = create<CorrectionStore>()(subscribeWithSelector(persist((set, get) => ({
    isAlternativeModeActive: true,
    setIsAlternativeModeActive: (value) => set({
        isAlternativeModeActive: value,
    }),
    text: '',
    segments: [],
    correctionMode: ADVICE_TYPE.Spelling,
    handleSetCorrectionMode: (mode) => set({ correctionMode: mode }),
    editorNode: null,
    setEditorNode: (node) => set({ editorNode: node }),
    handleSegmentize: async () => {
        const currentText: string | undefined = get().editorNode?.innerText;

        if (!currentText) return;

        if (currentText.length < 1500) {
            const editorNode = get().editorNode;
            if (editorNode) {
                editorNode.innerHTML = '';
            }

            return setTimeout(() => {
                // set({ segments: [] }); // TODO

                set({
                    segments: [
                        {
                            id: `segment-${new Date().getTime()}-0`,
                            beginOffset: 0,
                            text: currentText,
                            isStale: true,
                        }],
                });
            }, 1);
        }

        try {
            set({ isSegmentizeLoading: true, isSegmentizeSilentLoading: true });

            setTimeout(() => {
                set({ isSegmentizeSilentLoading: false });
            }, 2000);

            const response = await fetch(`${process.env.REACT_APP_API_URI}/apigateway/segmentize`, { // /segmentize-without-ai?_format=json
                method: 'POST',
                credentials: 'include',
                cache: 'no-cache',
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify({
                    text: currentText,
                    // tonality: 'neutral', //TODO: temporary solution
                }),
            });

            const responseData = await response.json();
            const segments: Omit<Segment, 'id'>[] = responseData.result;

            // clean up editor content
            const editorNode = get().editorNode;
            if (editorNode) {
                editorNode.innerHTML = '';
            }

            // set({ segments: [] }); // TODO

            const timestamp = new Date().getTime();

            const normalizedSegments = segments.map((segment) => {
                const id = `segment-${timestamp}-${segment.beginOffset}`;

                return { ...segment, id, isStale: true };
            });

            return set((state) => ({
                    isSegmentizeLoading: false,
                    segments: normalizedSegments,
                }),
            );
        } catch (err) {
            console.error('handleSegmentize ', err);
            set({ isSegmentizeLoading: false });

            return;
        }
    },
    isSegmentizeLoading: false,
    isSegmentizeSilentLoading: false,
    segmentsLoading: {},
    handleSegmentUpdate: ({ id, text }) => {
        set((store) => ({
            segments: store.segments.map((segment) => {
                if (segment.id === id) {
                    let newText = text;

                    if (typeof text === 'undefined') {
                        const segmentNode = get().editorNode?.querySelector(`#${id}`);

                        if (segmentNode) {
                            newText = (segmentNode as HTMLElement).innerText;
                        }
                    }

                    return newText ? {
                        ...segment,
                        currentText: newText,
                        isStale: true,
                    } : segment;
                }

                return segment;
            }),
        }));
    },
    handleSegmentGrammarcheck: async (id: string) => {
        const activeSegment: Segment | undefined = get().segments.find((segment: Segment) => segment.id === id);

        if (!activeSegment) return false;

        const originText = activeSegment.currentText || activeSegment.text;

        try {
            set({ segmentsLoading: { ...get().segmentsLoading, [activeSegment.id]: true } });
            const response = await RequestApi.fetch(
                RequestMethod.post,
                'api/grammarcheck',
                undefined,
                JSON.stringify({
                    text: originText,
                    userInteraction: false, //TODO
                    documentID: DocumentIdentifier.get(),
                    maxProposals: 7,
                }),
            );

            const responseData = await response.json();

            const { data: { spellAdvices, styleAdvices } }: { data: { spellAdvices: Advice[], styleAdvices: Advice[] } } = responseData;

            let resultText = originText;
            // const hasIssue = !!(message || shortMessage);
            const hasError = !!responseData.error || !responseData.charsRemaining;

            set((store) => {
                const normalizedSegments = store.segments.map((segment) => {
                    if (segment.id === id) {
                        const normalizedSpellAdvices = spellAdvices.map((advice) => (
                            {
                                ...advice,
                                id: `advice-${segment.beginOffset}-${advice.offset}`,
                                adviceType: ADVICE_TYPE.Spelling,
                                segmentId: segment.id,
                            }));
                        const normalizedStyleAdvices = styleAdvices.map((advice) => (
                            {
                                ...advice,
                                id: `advice-${segment.beginOffset}-${advice.offset}`,
                                adviceType: ADVICE_TYPE.Style,
                                segmentId: segment.id,
                            }));

                        const blocks = normalizeGrammarcheckBlocks(resultText, [...normalizedSpellAdvices, ...normalizedStyleAdvices]);
                        console.info('💥💥 Grammarcheck::blocks 💥💥 ', blocks); // TODO

                        return {
                            ...segment,
                            text: resultText,
                            originText,
                            currentText: resultText,
                            blocks,
                            isStale: false,
                            spellAdvices: normalizedSpellAdvices,
                            styleAdvices: normalizedStyleAdvices,
                        };
                    }

                    return segment;
                });

                return {
                    // summary: { ...store.summary, limit: responseData.charsTotal },
                    charsRemaining: responseData.charsRemaining ?? store.charsRemaining ?? 0,
                    segmentsLoading: { ...store.segmentsLoading, [activeSegment.id]: false },
                    segments: normalizedSegments,
                };
            });

            return !hasError;
        } catch (err) {
            console.error('handleSegmentize ', err);
            set({ segmentsLoading: { ...get().segmentsLoading, [activeSegment.id]: false } });
            return false;
        }

    },
    cleanEditorNode: () => set((state) => {
        if (state.editorNode) state.editorNode.innerHTML = '';

        return {
            text: '',
        };
    }),
    synonymQuery: '',
    handleSetSynonymQuery: (query) => set({
        synonymQuery: query,
    }),
    acceptedAdvice: null,
    handleAcceptAdvice: (data) => {
        if (data?.value) {
            const markSelector = `mark[data-llm-id="${data?.id}"]`;
            const correctedMark = get().editorNode?.querySelector(markSelector);

            if (correctedMark) {
                correctedMark.innerHTML = data.value;

                get().handleSegmentUpdate({ id: data.segmentId });
                get().handleSegmentGrammarcheck(data.segmentId);
            }
        }
    },
    adviceSuggestion: null,
    handleSetAdviceSuggestion: (data) => set({
        adviceSuggestion: data,
    }),
    handleGrammarcheck: async () => {
        const { isSegmentizeLoading, isSegmentizeSilentLoading, editorNode } = get();

        if (isSegmentizeLoading || isSegmentizeSilentLoading) return;

        const caretPosition = getCaretPosition(editorNode);

        if (checkCorruptedChildNode(editorNode)) {
            await get().handleSegmentize();
        }

        const currentSegments = get().segments;

        const filteredSegments = currentSegments
            .filter((segment) => {
                return segment.isStale;
            });

        for (const [segmentIndex, segment] of filteredSegments.entries()) {
            if (useOneClickStore.getState().isOneClickModeActive) break;

            const wasCorrected = get().handleSegmentGrammarcheck(segment.id);
            console.info(`💥💥 Segment ${segmentIndex} was${!wasCorrected ? 'n\'t' : ''} corrected 💥💥 `);
        }

        caretPosition && setTimeout(() => {
            setCaretPosition(editorNode, caretPosition);
        }, 1000); // TODO
    },
    bufferText: '',
    isGrammarcheckLoading: false,
    setBufferText: (text) => set(() => {
        const normalizedText = text.trim();

        return {
            bufferText: normalizedText,
            ...(!normalizedText && { segments: [], text: '' }),
        };
    }),
    syncBufferText: () => set((store) => ({
        text: store.bufferText,
    })),
    activeIndex: -1,
    selectedIndex: -1,
    handleSetHighlightedAdvice: (data) => {
        get().handleStopToCloseInlineAdvice();

        set({ highlightedAdvice: data })
    },
    handleSetActiveIndex: ({ id, value, shiftValue }) => set((store) => {
        if (id) {
            const [spellAdvices, styleAdvices] = selectAdvices(store);

            const currentAdvice = [...spellAdvices, ...styleAdvices].find((advice) => advice.id === id);

            if (!currentAdvice) return {};

            const correctionMode = currentAdvice?.adviceType;

            const advices = correctionMode === ADVICE_TYPE.Style ? styleAdvices : spellAdvices;

            const currentIndex = advices.findIndex((advice) => advice.id === id);

            if (currentIndex >= 0) {
                return {
                    activeIndex: currentIndex,
                    correctionMode,
                };
            }

            return {};
        }

        if (typeof value === 'number') {
            return {
                activeIndex: value,
            };
        }

        if (typeof shiftValue === 'number') {
            const currentIndex = store.activeIndex;
            const newIndex = currentIndex + shiftValue;
            const [advices] = selectAdvices(store);

            return {
                activeIndex: Math.max(0, Math.min(advices.length - 1, newIndex)),
            };
        }

        return {};
    }),
    handleStartToCloseInlineAdvice: () => {
        get().handleStopToCloseInlineAdvice();

        const closeInlineAdviceTimestamp = setTimeout(() => get().handleSetHighlightedAdvice(undefined), 1000);

        set({
            closeInlineAdviceTimestamp,
        })
    },
    handleStopToCloseInlineAdvice: () => {
        const closeInlineAdviceTimestamp = get().closeInlineAdviceTimestamp;

        if (closeInlineAdviceTimestamp) {
            clearTimeout(closeInlineAdviceTimestamp);

            set({
                closeInlineAdviceTimestamp: undefined,
            })
        }
    },
}), {
    name: 'CorrectionText',
    storage: createJSONStorage(() => sessionStorage),
    partialize: (state) => ({
        bufferText: state.bufferText,
    }),
})));

export const selectAdvices = (store: CorrectionStore) => {
    return store.segments.reduce((acc, segment) => {
        return [
            [...acc[0], ...(segment.spellAdvices ?? [])],
            [...acc[1], ...(segment.styleAdvices ?? [])],

        ] as [NormalizedAdvice[], NormalizedAdvice[]];
    }, [[], []] as [NormalizedAdvice[], NormalizedAdvice[]]);
};

export const selectActiveAdvice = (store: CorrectionStore) => {
    const [spellAdvices, styleAdvices] = selectAdvices(store);
    const activeIndex = store.activeIndex;
    const advices = store.correctionMode === ADVICE_TYPE.Style ? styleAdvices : spellAdvices;

    return activeIndex > -1 ? advices[activeIndex] : advices[0];
};

export const selectHighlightedAdvice = (store: CorrectionStore) => {
    const [spellAdvices, styleAdvices] = selectAdvices(store);
    const adviceId = store.highlightedAdvice?.id;

    return adviceId ? [...spellAdvices, ...styleAdvices].find(advice => advice.id === adviceId) : undefined;
};

export default useCorrectionStore;