import * as React from "react";
import { useSelector } from "react-redux";
import {selectUserFeatures, selectUserHasUsedTrial, selectUserShowDiscount, selectUserTrialDaysRemaining, selectUserHasHadPremium, UserRole} from "../../Store/UserState";
import "../../Styles/component/teaser/alert-space/alert-space.sass";
import { hasFeature, useUserRoles } from "../../Util/UserUtils";
import { GTMDataLayerPush } from "../../Util/GoogleTagManager";

export const AlertSpace: React.FC = () => {
    const userFeatures = useSelector(selectUserFeatures);
    const hasUsedTrial = useSelector(selectUserHasUsedTrial);
    const showDiscount = useSelector(selectUserShowDiscount);
    const hasHadPremium = useSelector(selectUserHasHadPremium);
    const trialDaysRemaining = useSelector(selectUserTrialDaysRemaining);
    const isAuthenticated = useUserRoles(UserRole.Authenticated);

    if (hasFeature("hideAlertSpace", userFeatures)) {
        // tslint:disable-next-line:no-null-keyword
        return null;
    }

    const buttonTrial = 'Jetzt Premium testen';
    const buttonBuy = 'Jetzt Premium kaufen';
    const buttonOffer = 'Zum Angebot';
    const linkAno = '/user/register/private';
    // All symbols in the destination parameter should be encoded like ?,& to be respected after Gluu redirection.
    const linkReg = process.env.REACT_APP_DUDENDE_URL + '/user/authenticate?destination=/abonnement';
    const isTrialParameter = 'type=duden-trial%26source=mentor';
    // tslint:disable-next-line:max-line-length
    const isItmParameter = 'plan=premium_monthly_pt_2&source=mentor&itm_source=duden_mentor&itm_medium=premium_int&itm_campaign=basis_angebot-795&itm_content=navi_cta';

    const discountLink = process.env.REACT_APP_DUDENDE_URL + '/user/authenticate?destination=/payment/' + process.env.REACT_APP_DISCOUNT_BILLWERK_ID;

    let buttonText = buttonTrial;
    let link = process.env.REACT_APP_DUDENDE_URL + linkAno;
    // When working with Gluu URls, we should be always use the encoded characters for ?,&.
    let trialParameter = '%3F' + isTrialParameter;
    let itmParameter = '';

    // Three flags can basically form 8 possible combinations,
    // but showDiscount can only be true if hasHadPremium is false
    // what leaves 5 combinations in total.
    if (isAuthenticated) {
        // 1. option
        link = linkReg;
        if (hasUsedTrial && !showDiscount) {
            // this represents options 2 and 3 for hasHadPremium t/f
            buttonText = buttonBuy;
            trialParameter = '';
        }
        else if (!hasHadPremium && showDiscount) {
            // this represents options 4 and 5 for hasUsedTrial t/f
            buttonText = buttonOffer;
            trialParameter = '';
            itmParameter = '?' + isItmParameter;
            link = discountLink;
        }
    }

    let testPeriod: JSX.Element | undefined;

    if (hasFeature("displayAsTrial", userFeatures)) {
        let remainingTime: string;
        switch (trialDaysRemaining) {
            case 2:
                remainingTime = "in 2 Tagen";
                break;
            case 1:
                remainingTime = "morgen";
                break;
            case 0:
                remainingTime = "heute";
                break;
            default:
                // tslint:disable-next-line:no-null-keyword
                return null;
        }

        testPeriod = <div className="alert-space__stage">Ihr Testzeitraum endet {remainingTime}</div>;
    }

    const onClickPremium = () => {
        if (buttonText === buttonTrial) {
            GTMDataLayerPush({event: "premium_touchpoint", option: "premium_alert_button"});
        }
    }

    return (
        <div id="alert-space" className="alert-space">
            {testPeriod}
            <a href={link + trialParameter + itmParameter} className="alert-space__button" onClick={onClickPremium}>
                {buttonText} →
            </a>
        </div>
    );
};
