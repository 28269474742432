import styled from "styled-components";

import { MediaBreakpoints } from "../../../Styles";
import { StyledBaseAdvice } from "../AdviceCard";

export const Wrapper = styled.div`
    margin: 0 0 20px;
`;

export const LoadingIcon = styled.div`
    position: absolute;
    top: 110px;
    left: 50%;
    transform: translateX(-50%);
    height: 15px;

    @media ${MediaBreakpoints.medium} {
        top: 70px;
    }

    @media ${MediaBreakpoints.wide} {
        top: 150px;
    }

    @media (min-width: 1310px) {
        top: 90px;
    }
`;

export const AdditionalCards = styled.div`
    ${StyledBaseAdvice};
    border-radius: 10px;
    cursor: pointer;
    margin: 0 0 15px;
    padding: 15px 20px;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.3)
    }

    h2 {
        justify-content: space-between;
        margin-bottom: 0;
    }
`;