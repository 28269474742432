import React, { useCallback } from 'react';

import { Popover, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';

import * as SC from './ExpressAppMenu.styled';
import useOneClickStore, { OneClickStore, selectBufferText } from '../../../Hooks/useOneClickStore';
import { selectFeatureFlags } from '../../../Store/SettingsState';
import { GTMDataLayerPush } from '../../../Util/GoogleTagManager';
import { ParaphrasingAcceptPopover } from '../../Popover';
import { AppMenuItem } from '../AppMenuItem';

type ExpressAppMenuProps = {
    isHeaderView?: boolean;
}

const ExpressAppMenu: React.FC<ExpressAppMenuProps> = ({ isHeaderView = false }) => {
    const featureFlags = useSelector(selectFeatureFlags);
    const isParaphrasingModeActive = useOneClickStore((store: OneClickStore) => store.isParaphrasingModeActive);
    const setIsParaphrasingModeActive = useOneClickStore((store: OneClickStore) => store.setIsParaphrasingModeActive);
    const handleCorrection = useOneClickStore((store) => store.handleCorrection);
    const bufferText = useOneClickStore(selectBufferText);

    const isParaphrasingFeatureActive = featureFlags['paraphrasing'];
    const isParaphrasingItemActive = isParaphrasingFeatureActive && isParaphrasingModeActive;

    const handleToggleParaphrasingMode = useCallback(() => {
        setIsParaphrasingModeActive(!isParaphrasingModeActive);
    }, [isParaphrasingModeActive, setIsParaphrasingModeActive]);

    const handleStartCorrection = useCallback(() => {
        GTMDataLayerPush({
            event: 'mentor_express_start'
        });
        handleCorrection();
    }, [handleCorrection]);

    const handleSpellcheckItemClick = useCallback(() => {
        if (!isParaphrasingModeActive) {
            handleStartCorrection()

            return;
        }

        GTMDataLayerPush({ event: 'app-menu', option: 'express_spellcheck' });
        handleToggleParaphrasingMode();
    }, [isParaphrasingModeActive, handleToggleParaphrasingMode, handleStartCorrection]);

    const handleParaphrasingItemClick = useCallback((event) => {
        if (!isParaphrasingFeatureActive) return;

        if (isParaphrasingModeActive) {
            event.preventDefault();
            handleStartCorrection()

            return;
        }

        if (!bufferText) {
            event.preventDefault();

            GTMDataLayerPush({ event: 'app-menu', option: 'express_rephrase' });
            handleToggleParaphrasingMode();
        }
    }, [isParaphrasingFeatureActive, isParaphrasingModeActive, bufferText, handleStartCorrection, handleToggleParaphrasingMode]);

    return <>
        <AppMenuItem
            isActive={!isParaphrasingItemActive}
            iconPath="/assets/icons/appmenu/ai-corrector.svg"
            tooltip={isHeaderView ? undefined : 'Korrigieren'}
            description={isHeaderView ? undefined : 'Korrigieren'}
            type="corrector"
            onClick={handleSpellcheckItemClick}
        />
        <Popover style={{ position: 'relative' }}>
            {({ open: isOpen, close: handleClose }) => (
                <>
                    <Popover.Button as="div" style={{ position: 'relative' }}>
                        <AppMenuItem
                            isActive={isParaphrasingItemActive}
                            isDisabled={!isParaphrasingFeatureActive}
                            iconPath="/assets/icons/appmenu/ai-paraphraser.svg"
                            tooltip={isHeaderView || isOpen ? undefined : (isParaphrasingFeatureActive ? 'Umformulieren' : 'Bald verfügbar')}
                            description={isHeaderView ? undefined : 'Umformulieren'}
                            type="paraphraser"
                            onClick={handleParaphrasingItemClick}
                        />
                    </Popover.Button>
                    <Transition
                        as={React.Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <SC.PopoverPanel isHeaderView={isHeaderView}>
                            <ParaphrasingAcceptPopover showArrow={!isHeaderView} onAction={handleToggleParaphrasingMode} handleClose={handleClose} />
                        </SC.PopoverPanel>
                    </Transition>
                </>
            )}
        </Popover>
    </>;
};

export default ExpressAppMenu;