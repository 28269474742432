import React from 'react';

import * as SC from './Footer.styled';

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Window {
        _sp_?: { loadPrivacyManagerModal?: (value: number) => void };
    }
}

const linksConfig = [{
    name: 'Privacy Manager',
    href: '#cmp',
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        window._sp_?.loadPrivacyManagerModal?.(883462);
    },
},
    {
        name: 'Datenschutz',
        href: '/datenschutzerklaerung',
    },
    {
        name: 'AGB',
        href: '/agb',
    },
    {
        name: 'Impressum',
        href: '/impressum',
    },
];

const Footer: React.FC = () => <SC.Wrapper>
    <SC.Copyright>&copy; Cornelsen Verlag GmbH, {new Date().getFullYear()}</SC.Copyright>
    <SC.LinksWrapper>
        {linksConfig.map((link, index) => (
            <a key={index} href={link.href} onClick={link.onClick} className="footer-menu-link">{link.name}</a>
        ))}
    </SC.LinksWrapper>
</SC.Wrapper>;

export default Footer;
