import {all, call, put, race, takeLatest, delay, Effect} from 'redux-saga/effects';
import {Action} from 'typescript-fsa';

import {
    addDictionaryEntryAction,
    addDictionaryEntryFailedAction,
    addDictionaryEntryPendingAction,
    addDictionaryEntrySuccessfulAction,
    deleteDictionaryEntryAction,
    deleteDictionaryEntryFailedAction,
    deleteDictionaryEntryPendingAction,
    deleteDictionaryEntrySuccessfulAction,
    getDictionaryEntriesAction,
    getDictionaryEntriesFailedAction,
    getDictionaryEntriesPendingAction,
    getDictionaryEntriesSuccessfulAction,
    AddDictionaryEntryPayload,
    DeleteDictionaryEntryPayload,
} from './Actions';
import {RequestApi, RequestMethod} from '../../Util/RequestApi';

export function* dictionarySaga(): IterableIterator<Effect> {
    yield all([
        takeLatest<Action<void>>(getDictionaryEntriesAction.type, getDictionaryEntriesHandler),
        takeLatest<Action<AddDictionaryEntryPayload>>(addDictionaryEntryAction.type, addDictionaryEntryHandler),
        takeLatest<Action<DeleteDictionaryEntryPayload>>(
            deleteDictionaryEntryAction.type,
            deleteDictionaryEntryHandler,
        ),
    ]);
}

/**
 * Dictionary entries response handler
 */
function* getDictionaryEntriesHandler({}: Action<void>): IterableIterator<Effect> {
    yield put(getDictionaryEntriesPendingAction(void(0)));
    try {
        const response = yield call(getDictionaryEntries);

        if (response.status === 200) {
            const json = yield call([response, response.json]);
            yield put(getDictionaryEntriesSuccessfulAction({dictionaryEntries: json.dictionary}));
        } else {
            const errorMessage = JSON.parse(yield call([response, response.text])).message;
            yield put(getDictionaryEntriesFailedAction(errorMessage));
        }
    } catch (err) {
        yield put(getDictionaryEntriesFailedAction(err.message));
    }
}

/**
 * Dictionary entries connection timeout handler
 * @param timeout
 */
function* getDictionaryEntries(timeout: number = 15000): IterableIterator<Effect> {
    const {response} = yield race({
        response: call(getDictionaryEntriesFetch),
        timeout: delay(timeout),
    });

    if (!response) {
        throw new Error('Connection timed out.');
    }

    return response;
}

/**
 * Fetch dictionary entries from api
 */
function getDictionaryEntriesFetch(): Promise<Response> {
    return RequestApi.fetch(RequestMethod.get, 'api/dictionary');
}

/**
 * Add entry to dictionary response handler
 * @param word
 * @param doNotCount
 */
function* addDictionaryEntryHandler(
    {payload: {word, doNotCount}}: Action<AddDictionaryEntryPayload>,
): IterableIterator<Effect> {
    yield put(addDictionaryEntryPendingAction(void(0)));
    try {
        const response = yield call(addDictionaryEntry, word);

        if (response.status === 201) {
            const json = yield call([response, response.json]);

            if (!doNotCount) {
                const localStorage: Storage = window.localStorage;
                const counterDictionary = localStorage.getItem('counter_dictionary');
                let counter = 0;

                if (counterDictionary) {
                    counter = parseInt(counterDictionary);
                }

                localStorage.setItem('counter_dictionary', `${counter + 1}`);
            }

            yield put(addDictionaryEntrySuccessfulAction({entry: {word: json.word, id: json.id}}));
        } else {
            const errorMessage = JSON.parse(yield call([response, response.text])).message;
            yield put(addDictionaryEntryFailedAction({errorMessage}));
        }
    } catch (err) {
        yield put(addDictionaryEntryFailedAction({errorMessage: err.message}));
    }
}

/**
 * Add entry to dictionary timeout handler
 * @param word
 * @param timeout
 */
function* addDictionaryEntry(word: string, timeout: number = 15000): IterableIterator<Effect> {
    const {response} = yield race({
        response: call(addDictionaryEntryFetch, word),
        timeout: delay(timeout),
    });

    if (!response) {
        throw new Error('Connection timed out.');
    }
    return response;
}

/**
 * Fetch add dictionary data from api
 * @param word
 */
function addDictionaryEntryFetch(word: string): Promise<Response> {
    return RequestApi.fetch(RequestMethod.post, 'api/dictionary', undefined, JSON.stringify({word}));
}

/**
 * Delete dictionary entry response handler
 * @param id
 */
function* deleteDictionaryEntryHandler(
    {payload: {id}}: Action<DeleteDictionaryEntryPayload>,
): IterableIterator<Effect> {
    yield put(deleteDictionaryEntryPendingAction(void(0)));
    try {
        const response = yield call(deleteDictionaryEntry, id);

        if (response.status === 204) {
            yield put(deleteDictionaryEntrySuccessfulAction({id}));
        } else {
            const errorMessage = JSON.parse(yield call([response, response.text])).message;
            yield put(deleteDictionaryEntryFailedAction({errorMessage}));
        }
    } catch (err) {
        yield put(deleteDictionaryEntryFailedAction(err.message));
    }
}

/**
 * Delete dictionary entry connection timeout handler
 * @param id
 * @param timeout
 */
function* deleteDictionaryEntry(id: number, timeout: number = 15000): IterableIterator<Effect> {
    const {response} = yield race({
        response: call(deleteDictionaryEntryFetch, id),
        timeout: delay(timeout),
    });

    if (!response) {
        throw new Error('Connection timed out.');
    }
    return response;
}

/**
 * Fetch delete dictionary entry data from api
 * @param id
 */
function deleteDictionaryEntryFetch(id: number): Promise<Response> {
    return RequestApi.fetch(RequestMethod.delete, `api/dictionary/${id}`);
}
