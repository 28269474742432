import React, { useCallback, useEffect } from 'react';

import { useSelector} from 'react-redux';
import { useDebounce } from 'use-debounce';

import * as SC from './CorrectionPad.styled';
import useCorrectionStore, { selectActiveAdvice } from '../../Hooks/useCorrectionStore';
import { DocumentIdentifier } from '../../Util/DocumentIdentifier';
import { gtmEventTypes, sendGTMEvent } from '../../Util/GoogleTagManager';
import { PlatformTypes } from '../../Util/PlatformUtil';
import { CorrectionTextSegment } from '../CorrectionTextSegment';


type CorrectionPadProps = {};

type OldStore = {
    userState: {
        autoLogInterval: number;
        characterLimit: number;
    }
}

const handleAutoLogText = (text: string) => fetch(`${URL}/api/log`, {
    method: 'POST',
    headers: new Headers({
        'Content-Type': 'application/json',
    }),
    credentials: 'same-origin',
    body: JSON.stringify({
        text,
    }),
})

const handleForceCrash = () => {
    const search = window.location.search;

    if (search) {
        const params = new URLSearchParams(search);
        const forceCrash = params.get('force_crash');

        if (forceCrash === 'true') {
            throw new Error('Crash forced!');
        }
    }
}

const CorrectionPad: React.FC<CorrectionPadProps> = () => {
    const text = useCorrectionStore(store => store.text);
    const segments = useCorrectionStore(store => store.segments);
    const [editorNode, setEditorNode] = useCorrectionStore((store) => [store.editorNode, store.setEditorNode]);
    const [bufferText, setBufferText] = useCorrectionStore((store) => [store.bufferText, store.setBufferText]);
    const syncBufferText = useCorrectionStore((store) => store.syncBufferText);
    const handleGrammarcheck = useCorrectionStore((store) => store.handleGrammarcheck);
    const handleSetSynonymQuery = useCorrectionStore((store) => store.handleSetSynonymQuery);
    const currentAdvice = useCorrectionStore(selectActiveAdvice);

    //TODO: move to zustand store
    const autoLogInterval = useSelector((store: OldStore) => store.userState.autoLogInterval)

    const [currentText] = useDebounce(bufferText, 1000);

    useEffect(() => {
        if (DocumentIdentifier.getPlatformIdentifier() !== PlatformTypes.lite && editorNode) {
            editorNode.focus();
        }

        let timeout: NodeJS.Timeout;

        if (autoLogInterval) {
            timeout = setTimeout(() => {
                const innerText = editorNode?.innerText;

                innerText && handleAutoLogText(innerText);
            }, autoLogInterval)
        }

        handleForceCrash();

        return () => {
            timeout && clearTimeout(timeout)
        }
    }, [autoLogInterval, editorNode]);

    useEffect(() => {
        syncBufferText();
    }, [syncBufferText]);

    useEffect(() => {
        handleGrammarcheck();
    }, [currentText, handleGrammarcheck]);

    const handleEditorChange = useCallback(() => {
        if (editorNode) {
            setBufferText(editorNode.innerText);
        }
    }, [editorNode, setBufferText]);

    const handleOnPaste = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        let paste = event.clipboardData.getData('text');
        const selection = window.getSelection();

        if (!selection?.rangeCount) return;
        selection.deleteFromDocument();

        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
        selection.collapseToEnd();
        handleEditorChange();
    }, [handleEditorChange]);

    const handleOnCopy = useCallback(() => {
        sendGTMEvent(gtmEventTypes.textCopied);
    }, [])

    const handleOnPointerUp: React.ReactEventHandler<HTMLDivElement> = useCallback((event) => {
        const selection = window.getSelection();

        if (selection?.type === 'Range') {
            const synonymQuery = selection.toString();
            const sanitizedSynonymQuery = synonymQuery.trim();

            if (sanitizedSynonymQuery && !sanitizedSynonymQuery.includes(' ')) {
                const [leftShift, rightShift] = synonymQuery.split(sanitizedSynonymQuery);
                let range = selection.getRangeAt(0);

                if (leftShift || rightShift) {
                    const startOffset = range.startOffset + leftShift.length;
                    const endOffset = range.endOffset - rightShift.length;
                    selection.setPosition(selection.anchorNode, startOffset);
                    selection.extend(selection.anchorNode as Node, endOffset);

                    range = selection.getRangeAt(0);
                }

                if (sanitizedSynonymQuery) {
                    handleSetSynonymQuery(sanitizedSynonymQuery);
                }
            }
        }

    }, [handleSetSynonymQuery]);

    return (
        <SC.Wrapper
            ref={setEditorNode}
            contentEditable
            suppressContentEditableWarning
            onCopy={handleOnCopy}
            onPaste={handleOnPaste}
            onInput={handleEditorChange}
            onPointerUp={handleOnPointerUp}
            placeholder="Beginnen Sie zu schreiben oder fügen Sie einen Text ein."
            activeId={currentAdvice?.id}
        >
            {!!segments.length ? segments.map((segment, index, segments) => <CorrectionTextSegment key={segment.id} previousKey={segments[index - 1]?.id} data={segment} />) : (syncBufferText ?? text)}
        </SC.Wrapper>
    );
};

export default CorrectionPad;