import styled from "styled-components";

import { Colors } from "../../../Styles";

export const Advise = styled.mark<{ isStyle: boolean }>`
    background-color: initial;
    background-image: linear-gradient(0deg, ${({ isStyle }) => isStyle ? Colors.periwinkle : Colors.supernova} 48%, transparent 0);
    background-size: 100% 300%;
    background-position: 50%;
    transition: background-position .25s;
    cursor: pointer;

    &:hover {
        background-position: center bottom
    }
`;

export const Suggestion = styled.mark<{ isStyle: boolean }>`
    background-color:  ${({ isStyle }) => isStyle ? Colors.periwinkle : Colors.supernova};
    background-position: 0;
`;
