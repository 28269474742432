import React, { useCallback, useEffect, useMemo } from 'react';

import { renderToStaticMarkup } from 'react-dom/server';

import { BlockContent } from './BlockContent/BlockContent';
import useCorrectionStore, { Segment } from '../../Hooks/useCorrectionStore';

type CorrectionTextSegmentProps = {
    data: Segment;
    previousKey?: string;
};

const CorrectionTextSegment: React.FC<CorrectionTextSegmentProps> = ({ data, previousKey }) => {
    const { id, text, blocks } = data;
    const editorNode = useCorrectionStore((store) => store.editorNode);
    const handleSegmentUpdate = useCorrectionStore((store) => store.handleSegmentUpdate);
    const handleSetActiveIndex = useCorrectionStore((store) => store.handleSetActiveIndex);
    const handleSetHighlightedAdvice = useCorrectionStore((store) => store.handleSetHighlightedAdvice);
    const handleStartToCloseInlineAdvice = useCorrectionStore((store) => store.handleStartToCloseInlineAdvice);
    const segmentSuggestion = useCorrectionStore(store => store.adviceSuggestion?.segmentId === id ? store.adviceSuggestion : null);

    const segmentNode = useMemo(() => {
        const spanNode = document.createElement('span');

        spanNode.classList.add('text-segment');
        // spanNode.contentEditable = 'true';

        if (id) {
            spanNode.id = id;
        }

        return spanNode;
    }, [id]);

    const handleAdviceSelect = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        const node = event.target;

        if (node.nodeName === 'MARK') {
            const adviceId = node.dataset.llmId;

            if (adviceId) {
                handleSetActiveIndex({ id: adviceId });
            }
        }
    }, [handleSetActiveIndex]);

    const handleAdviceHighlite = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        const node = event.target;

        if (node.nodeName === 'MARK') {
            const adviceId = node.dataset.llmId;

            if (adviceId) {
                node.onmouseleave = handleStartToCloseInlineAdvice;

                handleSetHighlightedAdvice({ id: adviceId });
            }
        }
    }, [handleSetHighlightedAdvice, handleStartToCloseInlineAdvice]);


    // append dom node
    useEffect(() => {
        const previousSegmentNode = previousKey && editorNode?.querySelector(`#${previousKey}`);

        if (previousSegmentNode) {
            previousSegmentNode.insertAdjacentElement('afterend', segmentNode);
        } else {
            editorNode?.insertAdjacentElement('afterbegin', segmentNode);
        }

    }, [editorNode, previousKey, segmentNode]);


    // cleanup dom node
    useEffect(() => {
        const mutationObserver = new MutationObserver((mutationList) => {
            for (const mutation of mutationList) {
                if (mutation.type === 'characterData') {
                    if (segmentNode?.innerText) {
                        handleSegmentUpdate({ id, text: segmentNode.innerText });
                    }
                }
            }
        });

        const intersectionObserver = new IntersectionObserver((mutationList) => {
            for (const mutation of mutationList) {
                if (mutation.isIntersecting) {
                    console.info('💥💥 --- is intersecting --- 💥💥 ');
                }
            }
        }, {
            root: editorNode,
            rootMargin: '0px',
            threshold: 1.0,
        });

        mutationObserver.observe(segmentNode, { attributes: true, childList: true, subtree: true, characterData: true });
        intersectionObserver.observe(segmentNode);

        return () => {
            mutationObserver.disconnect();
            intersectionObserver.disconnect();
            segmentNode?.remove();
        };
    }, [editorNode, handleSegmentUpdate, id, segmentNode]);

    useEffect(() => {
        console.info('💥💥 Segment rerender 💥💥 ');
        const blocksContent = renderToStaticMarkup(blocks?.length ? <BlockContent blocks={blocks} suggestionId={segmentSuggestion?.id} suggestion={segmentSuggestion?.suggestion} /> : <>{text}</>);

        segmentNode && (segmentNode.innerHTML = blocksContent);
    }, [blocks, segmentNode, segmentSuggestion?.id, segmentSuggestion?.suggestion, text]);

    useEffect(() => {
        segmentNode?.addEventListener('click', handleAdviceSelect);

        return () => {
            segmentNode?.removeEventListener('click', handleAdviceSelect);
        };
    }, [handleAdviceSelect, segmentNode]);

    useEffect(() => {
        segmentNode?.addEventListener('mouseover', handleAdviceHighlite);

        return () => {
            segmentNode?.removeEventListener('mouseover', handleAdviceHighlite);
        };
    }, [handleAdviceHighlite, segmentNode]);

    return null;
};

export default CorrectionTextSegment;