import * as React from "react";
import { useSelector } from "react-redux";
import { Router } from "./Components/Router";
import { Settings } from "library/src/Components/Settings/Settings";
import { User } from "library/src/Components/User/User";
import { MarketingDesk } from "library/src/Components/Desk/MarketingDesk";
import { selectUserStateFromMainState, UserRole } from 'library/src/Store/UserState'
import { DocumentIdentifier } from "library/src/Util/DocumentIdentifier";
import { PlatformTypes } from "library/src/Util/PlatformUtil";
import { useEffect, useState } from "react";

export const App = () => {
    const userState = useSelector(selectUserStateFromMainState);
    const [view, setView] = useState(<React.Fragment />)

    useEffect(() => {
        const result = userState.roles.filter(role => role === UserRole.Gast).length === 1;
        DocumentIdentifier.setPlatformIdentifier(result ? PlatformTypes.lite : PlatformTypes.full);

        // initialUser object has -1. Guest user has 0 and logged ind users have an id > 0.
        if (userState.id !== -1) {
            setView(result ? <MarketingDesk/> : <Router/>);
        }
    }, [userState.roles, userState.id])

    return (
        <Settings>
            <User>
                {view}
            </User>
        </Settings>
    );
}