import { GrammarcheckBlock, NormalizedAdvice } from '../Hooks/useCorrectionStore';
import { CorrectionBlock, ERROR_CODE, type LLMError } from "../Hooks/useOneClickStore";


export const normalizeCorrectionBlocks = (text: string, errors?: LLMError[], options?: { showTraces: boolean }) => {
    const { showTraces } = options ?? {};
    const blocks: CorrectionBlock[] = [];
    let sinitizedText = text;

    const sortedErrors = errors?.sort((a, b) => b.offset - a.offset || a.errorcode.localeCompare(b.errorcode)) ?? [];

    if (sortedErrors?.length) {
        sortedErrors.forEach((error) => {
            if (showTraces && error.errorcode === ERROR_CODE.Remove) {
                return;
            }

            if (!showTraces && error.errorcode === ERROR_CODE.Trace) {
                return;
            }

            const commonCopy = sinitizedText?.substring(error.offset + error.length);
            const markCopy = error.errorcode === ERROR_CODE.Trace ? error.proposals[0] : sinitizedText?.substring(error.offset, error.offset + error.length);

            if (!markCopy) return;

            commonCopy && blocks.unshift({
                type: 'common',
                copy: commonCopy
            })

            blocks.unshift({
                type: error.errorcode,
                copy: markCopy
            })

            sinitizedText = sinitizedText?.substring(0, error.offset);
        });

        sinitizedText && blocks.unshift({
            type: 'common',
            copy: sinitizedText
        })
    } else {
        blocks.push({
            type: 'common',
            copy: sinitizedText,
        });
    }

    return blocks;
}

export const normalizeGrammarcheckBlocks = (text: string, advices?: NormalizedAdvice[]) => {
    const blocks: GrammarcheckBlock[] = [];
    let sinitizedText = text;

    const sortedAdvices = advices?.sort((a, b) => b.offset - a.offset) ?? [];

    if (sortedAdvices?.length) {
        sortedAdvices.forEach((advice) => {
            const commonCopy = sinitizedText?.substring(advice.offset + advice.length);
            const markCopy = advice.originalError;

            if (!markCopy) return;

            commonCopy && blocks.unshift({
                type: 'common',
                copy: commonCopy
            })

            console.info('💥💥 s advice 💥💥 ', advice);

            blocks.unshift({
                id: advice.id,
                type: advice.adviceType,
                copy: markCopy
            })

            sinitizedText = sinitizedText?.substring(0, advice.offset);
        });

        sinitizedText && blocks.unshift({
            type: 'common',
            copy: sinitizedText
        })
    } else {
        blocks.push({
            type: 'common',
            copy: sinitizedText,
        });
    }

    return blocks;
}

export const setCaretPosition = (node: HTMLDivElement | ChildNode | null, caretPosition: number): void => {
    if (!node) return;

    let position = caretPosition;

    if (node.nodeType === Node.TEXT_NODE) {
        if (position <= node.textContent!.length) {
            const range = document.createRange();
            range.setStart(node, position);
            range.setEnd(node, position);

            const selection = window.getSelection();
            selection?.removeAllRanges();
            selection?.addRange(range);
        }
    } else {
        for (const childNode of node.childNodes) {
            const childNodeLength = childNode.textContent?.length;

            if (typeof childNodeLength === 'undefined') return;

            if (childNodeLength >= position) {
                return setCaretPosition(childNode, position);
            }

            position = position - childNodeLength;
        }
    }
}

export const getCaretPosition = (node: HTMLDivElement | null) => {
    if (!node) return;

    const selection = window.getSelection();

    if (selection?.rangeCount && selection.rangeCount > 0) {
        const range = selection?.getRangeAt(0);
        const clonedRange = range?.cloneRange();

        if (!range || !clonedRange) return;

        clonedRange.selectNodeContents(node);
        clonedRange.setEnd(range.endContainer, range.endOffset);

        return clonedRange.toString().length;
    }

    return;
};