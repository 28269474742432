import { Md5 } from "ts-md5";
import { create } from "zustand";
import { persist, createJSONStorage, subscribeWithSelector } from "zustand/middleware";
import { shallow } from "zustand/shallow";

import { UserRole } from "../Store/UserState";
import { normalizeCorrectionBlocks } from "../Util/oneClickCorrections";
import { hasAnyRole } from "../Util/UserUtils";

const ENDING_REGEX = /\s+$/gm;

type ObjectValues<T> = T[keyof T];

export const PARAPHRASING_MODE = {
    Neutral: 'neutral',
    Lighter: 'simpler',
    Formal: 'formal',
} as const;
export type ParaphrasingMode = ObjectValues<typeof PARAPHRASING_MODE>

export const DATA_SELECTOR = {
    Neutral: 'dataNeutral',
    Lighter: 'dataSimpler',
    Formal: 'dataFormal',
    Base: 'data',
} as const;
export type DataSelector = ObjectValues<typeof DATA_SELECTOR>

export const ERROR_CODE = {
    Replace: 'replace',
    Insert: 'insert',
    Remove: 'remove',
    Trace: 'trace',
} as const;
export type ErrorCode = ObjectValues<typeof ERROR_CODE>

export type LLMError = {
    type: string;
    errorcode: ErrorCode;
    offset: number;
    length: number;
    proposals: string[];
}

export type CorrectionBlock = { type: ErrorCode | "common"; copy: string };

export type Alignment = {
    diff: LLMError[];
    length: number;
    mate: string;
    offset: number;
    text: string;
}

export type Segment = {
    id: string;
    beginOffset: number;
    endOffset: number;
    currentText?: string;
    text: string;
    originText?: string;
    blocks?: CorrectionBlock[];
    traceBlocks?: CorrectionBlock[];
    isSkipped?: boolean;
    error?: boolean;
    corrections?: LLMError[];
    issue?: {
        code?: string;
        title?: string;
        message?: string;
    }
};

type StoreData = {
    text: string;
    segments: Segment[];
    bufferText: string;
}

export type OneClickStore = {
    isOneClickModeActive: boolean;
    setIsOneClickModeActive: (value: boolean) => void;
    isParaphrasingModeActive: boolean;
    setIsParaphrasingModeActive: (value: boolean) => void;
    paraphrasingMode: ParaphrasingMode,
    setParaphrasingMode: (value: ParaphrasingMode) => void,
    isHighlightModeActive: boolean;
    setIsHighlightModeActive: (value: boolean, silent?: boolean) => void;
    requestsRemaining?: number;
    setSegment: (segmentId: string, segmentData: Partial<Segment>) => void;
    setSegments: (segments: Segment[]) => void;
    editorNode: HTMLDivElement | null;
    cleanEditorNode: () => void;
    setEditorNode: (node: HTMLDivElement | null) => void;
    handleSegmentize: () => Promise<void>;
    isCorrectionFinished: boolean;
    setIsCorrectionFinished: (value: boolean) => void;
    handleCorrection: () => Promise<void>;
    handleSegmentCorrection: (id: string) => Promise<boolean>;
    segmentsLoading: Record<string, boolean>;
    isSegmentLoading: (id: string) => boolean;
    isSegmentizeLoading: boolean;
    isSegmentizeSilentLoading: boolean;
    singleCorrectionActiveIndex: number;
    handleSetSingleCorrectionActiveIndex: (props: { id?: string; value?: number; shiftValue?: number }) => void;
    isOneClickAgreed: boolean;
    setOneClickAgreed: (value: boolean) => Promise<boolean>;
    isExpressOnboarded: boolean;
    setIsExpressOnboarded: () => Promise<boolean>;
    setBufferText: (text: string) => void;
    syncBufferText: () => void;
    handleCopyEditorText: (...args: DataSelector[]) => void;
    summary: {
        plan?: "premium" | "basic",
        limit?: number,
        limitMax?: number,
    },
    hashes: {
        userHash?: string,
        roleHash?: string,
    };
    setHashes: (email: string, roles: UserRole[]) => void;
    handleInitSummary: (hashes: OneClickStore['hashes']) => void;
} & Record<DataSelector, StoreData>

export const getDataSelector = (state: OneClickStore): DataSelector => {
    if (state.isParaphrasingModeActive) {
        switch (state.paraphrasingMode) {
            case PARAPHRASING_MODE.Neutral:
                return DATA_SELECTOR.Neutral;
            case PARAPHRASING_MODE.Lighter:
                return DATA_SELECTOR.Lighter;
            case PARAPHRASING_MODE.Formal:
                return DATA_SELECTOR.Formal;
        }
    }

    return DATA_SELECTOR.Base;
};

const useOneClickStore = create<OneClickStore>()(subscribeWithSelector(persist((set, get) => ({
    isOneClickModeActive: window.location.hash.includes("express"),
    setIsOneClickModeActive: (value) => set({
        isOneClickModeActive: value,
        isCorrectionFinished: false,
        singleCorrectionActiveIndex: -1,
        isSegmentizeLoading: false,
        segmentsLoading: {},
    }),
    isParaphrasingModeActive: window.location.hash.includes("express_paraphrasing"),
    setIsParaphrasingModeActive: (value) => set({
        isParaphrasingModeActive: value,
        isCorrectionFinished: false,
        singleCorrectionActiveIndex: -1,
        isSegmentizeLoading: false,
        segmentsLoading: {},
    }),
    paraphrasingMode: PARAPHRASING_MODE.Neutral,
    setParaphrasingMode: (value) => set({
        paraphrasingMode: value,
        isCorrectionFinished: false,
        singleCorrectionActiveIndex: -1,
        isSegmentizeLoading: false,
        segmentsLoading: {},
    }),
    isHighlightModeActive: true,
    setIsHighlightModeActive: (value, isSilent) => {
        if (isSilent && get().singleCorrectionActiveIndex >= 0) {
            return;
        }

        let singleCorrectionActiveIndex = -1;

        if (value) {
            const store = get();
            const dataSelector = getDataSelector(store);
            if (store[dataSelector].segments[0]) {
                singleCorrectionActiveIndex = 0;
            }
        }

        return set({
            isHighlightModeActive: value,
            singleCorrectionActiveIndex,
        });
    },
    ...Object.values(DATA_SELECTOR).reduce(
        (accumulator, dataSelector) => ({
            ...accumulator, [dataSelector]: {
                text: "",
                bufferText: "",
                segments: []
            }
        }),
        {}
    ) as Record<DataSelector, StoreData>,
    setSegment: (segmentId, segmentData) => set((state) => {
        const dataSelector = getDataSelector(state);

        return {
            [dataSelector]: {
                ...state[dataSelector],
                segments: state[dataSelector].segments.map((segment) => {
                    if (segment.id === segmentId) {
                        return { ...segment, ...segmentData };
                    }

                    return segment;
                })
            } as StoreData
        };
    }),
    setSegments: (items) => set((state) => {
        const dataSelector = getDataSelector(state);

        return {
            [dataSelector]: {
                ...state[dataSelector],
                segments: items
            } as StoreData
        };
    }),
    segmentsLoading: {},
    isSegmentLoading: (id) => !!get().segmentsLoading[id],
    isSegmentizeLoading: false,
    isSegmentizeSilentLoading: false,
    editorNode: null,
    cleanEditorNode: () => set((state) => {
        const dataSelector = getDataSelector(state);

        if (state.editorNode) state.editorNode.innerHTML = "";

        return {
            singleCorrectionActiveIndex: -1,
            [dataSelector]: {
                text: "",
                bufferText: "",
                segments: []
            } as StoreData
        };
    }),
    setEditorNode: (node) => set({ editorNode: node }),
    handleSegmentize: async () => {
        const currentText = get().editorNode?.innerText;

        if (!currentText) return;

        try {
            set({ isSegmentizeLoading: true, isSegmentizeSilentLoading: true, singleCorrectionActiveIndex: -1 });

            setTimeout(() => {
                set({ isSegmentizeSilentLoading: false });
            }, 2000);

            const response = await fetch(`${process.env.REACT_APP_API_URI}/apigateway/segmentize`, {
                method: "POST",
                credentials: "include",
                cache: "no-cache",
                headers: new Headers({
                    "Content-Type": "application/json"
                }),
                body: JSON.stringify({
                    text: currentText,
                    ...(get().isParaphrasingModeActive && {
                        tonality: get().paraphrasingMode
                    }),
                    ...get().hashes
                })
            });

            const responseData: {
                result?: Omit<Segment, "id">[],
                requestsRemaining?: number,
                requestsTotal?: number,
            } = await response.json();

            const {
                result: segments = [],
                requestsRemaining,
                requestsTotal,
            } = responseData;

            // clean up editor content
            const editorNode = get().editorNode;
            if (editorNode) {
                editorNode.innerHTML = "";
            }

            const dataSelector = getDataSelector(get());

            const currentSegments: Segment[] = [...get()[dataSelector].segments];

            // set({ [dataSelector]: { ...get()[dataSelector], segments: [] } }); // TODO

            const timestamp = new Date().getTime();

            const normalizedSegments = segments.map((segment) => {
                const id = `segment-${timestamp}-${segment.beginOffset}`;

                const existingSegment = currentSegments.find((currentSegment) => {
                    const actualText = currentSegment.currentText ?? currentSegment.text;
                    return actualText.trim() === segment.text?.trim();
                });

                if (!get().isParaphrasingModeActive && existingSegment) {
                    return {
                        ...existingSegment,
                        originText: segment.text,
                        text: segment.text,
                        currentText: segment.text,
                        ...(segment.text !== existingSegment.text && {
                            blocks: normalizeCorrectionBlocks(segment.text, existingSegment.corrections),
                            traceBlocks: normalizeCorrectionBlocks(segment.text, existingSegment.corrections, { showTraces: true })
                        }),
                        id
                    };
                }

                return { ...segment, id };
            });

            return set((state) => ({
                    isSegmentizeLoading: false,
                    summary: { ...state.summary, limit: requestsTotal },
                    requestsRemaining: requestsRemaining ?? state.requestsRemaining ?? 0,
                    [dataSelector]: {
                        ...state[dataSelector],
                        segments: normalizedSegments
                    }
                })
            );
        } catch (err) {
            console.error("handleSegmentize ", err);
            set({ isSegmentizeLoading: false });

            return;
        }
    },
    isCorrectionFinished: false,
    setIsCorrectionFinished: (value) => set({ isCorrectionFinished: value }),
    handleCorrection: async () => {
        const { isSegmentizeLoading, isSegmentizeSilentLoading, isParaphrasingModeActive, paraphrasingMode } = get();

        if (isSegmentizeLoading || isSegmentizeSilentLoading) return;

        await get().handleSegmentize();

        const dataSelector = getDataSelector(get());

        const segmentsLoading: OneClickStore['segmentsLoading'] = {};
        const currentSegments = get()[dataSelector].segments;
        const filteredSegments = currentSegments
            .filter((segment) => {
                const isNotCorrected = !segment.currentText || segment.currentText !== segment.text || !!segment.error;

                if (isNotCorrected) {
                    segmentsLoading[segment.id] = true;
                }

                return isNotCorrected;
            });

        set({
            segmentsLoading,
            ...(!filteredSegments.length && { isCorrectionFinished: true })
        });

        let nextCorrectionActiveIndex = -1;
        let isCorrectionFinished = true;

        for (const [segmentIndex, segment] of filteredSegments.entries()) {
            if (!segment.currentText || segment.currentText !== segment.text) {
                if (!get().isOneClickModeActive || get().isParaphrasingModeActive !== isParaphrasingModeActive || get().paraphrasingMode !== paraphrasingMode) break;

                const wasCorrected = await get().handleSegmentCorrection(segment.id);

                if (!wasCorrected) {
                    isCorrectionFinished = false;
                    set({
                        segmentsLoading: {}
                    });
                    break;
                } else {
                    if (segmentIndex === 0 && get().singleCorrectionActiveIndex === -1) {
                        nextCorrectionActiveIndex = currentSegments.findIndex((listSegment) => listSegment.id === segment.id);
                    }
                }
            }
        }

        nextCorrectionActiveIndex !== -1 && set({ singleCorrectionActiveIndex: nextCorrectionActiveIndex });
        isCorrectionFinished && set({ isCorrectionFinished });
    },
    handleSegmentCorrection: async (id) => {
        const dataSelector = getDataSelector(get());

        const activeSegment: Segment | undefined = get()[dataSelector].segments.find((segment: Segment) => segment.id === id);

        if (!activeSegment) return false;

        const originText = activeSegment.currentText || activeSegment.text;

        const isParaphrasingModeActive = get().isParaphrasingModeActive;
        const url = `${process.env.REACT_APP_API_URI}/apigateway/${isParaphrasingModeActive ? 'paraphrase' : 'llm_diff'}`;

        try {
            set({ segmentsLoading: { ...get().segmentsLoading, [activeSegment.id]: true } });
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                cache: 'no-cache',
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify({
                    text: originText,
                    ...(isParaphrasingModeActive && {
                        tonality: get().paraphrasingMode
                    }),
                    ...get().hashes
                })
            });

            const responseData: {
                checkResults?: {
                    text?: string,
                    alignment?: Alignment[],
                    errors: LLMError[],
                    code?: string,
                },
                messages?: { message: string, shortMessage: string },
                error?: string,
            } = await response.json();

            const { text, alignment, errors, code } = responseData.checkResults ?? {};
            let resultText = text ?? alignment?.map(alignmentItem => alignmentItem.text).join('') ?? '';

            const { message, shortMessage } = responseData.messages ?? (resultText ? {} : {
                message: 'Leider ist etwas schiefgegangen. Bitte starten Sie die Textprüfung erneut.',
            });

            const hasIssue = !!(message || shortMessage);
            const hasError = !!responseData.error;

            // keep spacing from origin text in case of non paraphrasing mode
            if (text) {
                const originTextRightSpace = originText.search(ENDING_REGEX);
                const resultTextRightSpace = resultText.search(ENDING_REGEX);

                if (originTextRightSpace !== resultTextRightSpace && originTextRightSpace !== -1) {
                    resultText = (resultTextRightSpace === -1 ? resultText : resultText?.substring(0, resultTextRightSpace)) + originText.substring(originTextRightSpace);
                }
            }

            if (hasIssue && !resultText) {
                resultText = originText;
            }

            const blocks = normalizeCorrectionBlocks(resultText, errors);
            const traceBlocks = normalizeCorrectionBlocks(resultText, errors, { showTraces: true });

            set((store) => {
                const normalizedSegments = !hasIssue && alignment?.length && alignment.length > 1 ?
                    store[dataSelector].segments.reduce((accumulator: Segment[], segment) => {
                        if (segment.id === id) {
                            const alignmentSegments: Segment[] = alignment.map((alignmentItem) => ({
                                ...segment,
                                id: `${segment.id}-alignment-${alignmentItem.offset}`,
                                beginOffset: segment.beginOffset + alignmentItem.offset,
                                text: alignmentItem.text,
                                originText: alignmentItem.mate,
                                currentText: alignmentItem.text,
                                blocks: [],
                                traceBlocks: [],
                                isSkipped: false,
                                corrections: alignmentItem.diff,
                            }))

                            return [...accumulator, ...alignmentSegments];
                        }

                        return [...accumulator, segment]
                    }, []) :
                    store[dataSelector].segments.map((segment) => {
                        if (segment.id === id) {

                            return {
                                ...segment,
                                text: resultText,
                                originText,
                                currentText: resultText,
                                blocks,
                                traceBlocks,
                                isSkipped: false,
                                corrections: errors,
                                ...(hasIssue && {
                                    issue: {
                                        code: code ?? 'llm_3',
                                        title: shortMessage,
                                        message,
                                    }
                                }),
                                ...(hasError && {
                                    error: responseData.error || 'Error',
                                    text: originText,
                                    blocks: [],
                                    traceBlocks: [],
                                })
                            };
                        }

                        return segment;
                    });

                return {
                    segmentsLoading: { ...store.segmentsLoading, [activeSegment.id]: false },
                    [dataSelector]: {
                        ...store[dataSelector],
                        segments: normalizedSegments
                    } as StoreData
                };
            });

            return !hasError;
        } catch (err) {
            console.error("handleSegmentize ", err);
            set({ segmentsLoading: { ...get().segmentsLoading, [activeSegment.id]: false } });
            return false;
        }

    },
    singleCorrectionActiveIndex: -1,
    handleSetSingleCorrectionActiveIndex: ({ id, value, shiftValue }) => set((store) => {
        const dataSelector = getDataSelector(store);

        if (id) {
            const currentIndex = store[dataSelector].segments.findIndex((segment: Segment) => segment.id === id);

            if (currentIndex >= 0) {
                return {
                    singleCorrectionActiveIndex: value
                };
            }

            return {};
        }

        if (typeof value === "number") {
            return {
                singleCorrectionActiveIndex: value
            };
        }

        if (typeof shiftValue === "number") {
            const currentIndex = store.singleCorrectionActiveIndex;
            const newIndex = currentIndex + shiftValue;

            return {
                singleCorrectionActiveIndex: Math.max(0, Math.min(store[dataSelector].segments.length - 1, newIndex))
            };
        }

        return {};
    }),
    isOneClickAgreed: false,
    setOneClickAgreed: async (value) => {
        set({ isOneClickAgreed: true });

        if (value) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/user/user_flags`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    }),
                    body: JSON.stringify({
                        hide_consent: value
                    })
                });

                const responseData: {
                    status: string,
                    message?: string,
                } = await response.json();

                const hasError = responseData.status === 'Error' || responseData.message;

                return !hasError;
            } catch (err) {
                console.error('setOneClickAgreed', err);
                return false;
            }
        }
        return false;
    },
    isExpressOnboarded: false,
    setIsExpressOnboarded: async () => {
        set({ isExpressOnboarded: true });

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/user/user_flags`, {
                method: 'POST',
                credentials: 'include',
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify({
                    hide_onboarding: true
                })
            });

            const responseData: {
                status: string,
                message?: string,
            } = await response.json();

            const hasError = responseData.status === 'Error' || responseData.message;

            return !hasError;
        } catch (err) {
            console.error('setIsExpressOnboarded', err);
            return false;
        }
    },
    setBufferText: (text) => set((state) => {
        const dataSelector = getDataSelector(state);
        const normalizedText = text.trim();

        return {
            [dataSelector]: {
                ...state[dataSelector],
                bufferText: normalizedText,
                ...(!normalizedText && { segments: [], text: '' }),
            } as StoreData
        };
    }),
    syncBufferText: () => set((state) => {
        const dataSelectors = Object.values(DATA_SELECTOR);

        const newState: Partial<OneClickStore> = {};

        for (const dataSelector of dataSelectors) {
            newState[dataSelector] = {
                ...state[dataSelector],
                text: state[dataSelector].bufferText,
            };
        }

        return newState;
    }),
    handleCopyEditorText: (...args) => {
        set((state) => {
            const innerText = state.editorNode?.innerText?.trim();

            if (!innerText || !args.length) return {};

            return args.reduce((accumulator, dataSelector) => ({
                ...accumulator,
                [dataSelector]: {
                    text: innerText,
                    bufferText: innerText,
                    segments: [],
                } as StoreData,
            }), {})
        })
    },
    summary: {},
    hashes: {},
    setHashes: (email, roles = []) => {
        if (!email) return;

        const userHash = Md5.hashStr(email);
        const isPremium = hasAnyRole(roles, UserRole.Premium, UserRole.Premium20, UserRole.B2B_Licensemanager_Premium20, UserRole.B2B_Licensee_Premium20, UserRole.B2B_Licensemanager_charity_Premium, UserRole.B2B_Licensee_charity_Premium);
        const plan = isPremium ? "premium" : "basic";
        const roleHash = Md5.hashStr(`${userHash}${plan}`);

        get().handleInitSummary({ userHash, roleHash });

        return set((store) => ({
            summary: {
                ...store.summary,
                plan
            },
            hashes: {
                userHash,
                roleHash
            }
        }));
    },
    handleInitSummary: async (hashes) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/apigateway/quota`, {
                method: "POST",
                credentials: "include",
                cache: "no-cache",
                headers: new Headers({
                    "Content-Type": "application/json"
                }),
                body: JSON.stringify(hashes)
            });

            const {
                plans,
                requestsTotal,
                requestsRemaining,
            } : {
                plans?: Record<'basic_char_limit' | 'premium_req_limit' | 'premium_char_limit' | 'premium_plus_req_limit' | 'premium_plus_char_limit', string>,
                requestsTotal: number,
                requestsRemaining: number,
            } = await response.json();

            return set((store) => {
                return {
                    summary: {
                        ...store.summary,
                        limit: requestsTotal,
                        limitMax: plans?.premium_req_limit ? Number(plans.premium_req_limit) : 0,
                    },
                    requestsRemaining: store.requestsRemaining ?? requestsRemaining,
                }
            });
        } catch (err) {
            console.error('quotaRequest', err);
            return;
        }

    }
}), {
    name: "OneClickText",
    storage: createJSONStorage(() => sessionStorage),
    partialize: (state) => ({
        ...Object.values(DATA_SELECTOR).reduce(
            (accumulator, dataSelector) => ({ ...accumulator, [dataSelector]: state[dataSelector] }),
            {},
        ),
        paraphrasingMode: state.paraphrasingMode,
        isHighlightModeActive: state.isHighlightModeActive,
        isExpressOnboarded: state.isExpressOnboarded,
    })
})));

useOneClickStore.subscribe(
    (store) => [store.isOneClickModeActive, store.isParaphrasingModeActive],
    ([isOneClickModeActive, isParaphrasingModeActive]) => {
        const newUrl = new URL(window.location.href);

        newUrl.hash = isOneClickModeActive ? (isParaphrasingModeActive ? "express_paraphrasing" : "express") : "";

        window.history.replaceState(
            {},
            "",
            newUrl.toString());
    },
    { equalityFn: shallow }
);

export const selectIsSegmentsLoading = (store: OneClickStore) => store.isSegmentizeLoading || store.isSegmentizeSilentLoading || Object.values(store.segmentsLoading).some(segmentId => segmentId);

export const selectOneClickPercentage = (store: OneClickStore) => {
    const dataSelector = getDataSelector(store);
    const segmentsCount = store[dataSelector].segments.length;
    const isSegmentizeLoading = store.isSegmentizeLoading;

    if (isSegmentizeLoading) {
        return 0;
    }

    if (segmentsCount) {
        const readySegmentsCount = store[dataSelector].segments.filter(segment => segment.currentText === segment.text).length;

        return readySegmentsCount / segmentsCount * 100;
    }

    return 0;
};

export const selectText = (store: OneClickStore) => {
    const dataSelector = getDataSelector(store);

    return store[dataSelector].text;
};

export const selectSegments = (store: OneClickStore) => {
    const dataSelector = getDataSelector(store);

    return store[dataSelector].segments;
};

export const selectBufferText = (store: OneClickStore) => {
    const dataSelector = getDataSelector(store);

    return store[dataSelector].bufferText;
};

export default useOneClickStore;