import React from "react";

import * as SC from "./BlockContent.styled";
import { ADVICE_TYPE, Segment } from "../../../Hooks/useCorrectionStore";

type BlockContentProps = {
    blocks: Segment["blocks"];
    suggestionId?: string;
    suggestion?: string;
};

export const BlockContent: React.FC<BlockContentProps> = ({blocks, suggestionId, suggestion}) => {
    return <>
        {blocks?.map((block, index) => {
            if (block.type === ADVICE_TYPE.Spelling || block.type === ADVICE_TYPE.Style) {
                if (block.id && suggestionId && suggestion && block.id === suggestionId) {
                    return <SC.Suggestion data-llm-id={block.id} isStyle={block.type === ADVICE_TYPE.Style}>{suggestion}</SC.Suggestion>
                }
                return <SC.Advise key={index} data-llm-id={block.id} data-llm-type={block.type} isStyle={block.type === ADVICE_TYPE.Style}>{block.copy}</SC.Advise>
            }

            if (block.type === 'common') {
                return <span key={index}>{block.copy}</span>
            }

            return null;
        })}
    </>;
};