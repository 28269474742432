import styled, { css } from 'styled-components';

import { Colors, Fonts } from '../../Styles';

export const Wrapper = styled.div<{ activeId?: string }>`
    width: 100%;
    height: calc(100% - 70px);
    overflow-y: auto;
    white-space: pre-wrap;
    font-family: ${Fonts.publico};
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 60px;
    box-sizing: border-box;

    *::selection {
        background-color: ${Colors.supernova};
    }

    &:focus-visible {
        outline: none;
    }

    &[placeholder]:empty:before {
        content: attr(placeholder);
        color: #ccc;
        font-size: 2em;
        line-height: normal;
        display: block;
        height: 25vh;
        margin-bottom: -25vh;
        transition: opacity 0.25s;
        pointer-events: none;
    }

    ${(props) => props.activeId && css`
        [data-llm-id="${props.activeId}"] {
            background-position: center bottom;
        }   
    `}
`;